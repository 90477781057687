import buildUrl from 'shared/helpers/utils/buildUrl';
import tryGetJson from 'shared/helpers/utils/tryGetJson';

// Add params prop to pass query parameters as an object using native fetch
const fetchWithParams = (url, { params = {}, rawResponse, ...rest } = {}) => {
  // const urlWithQuery = new URL(url);

  // if (params) {
  //   Object.keys(params).forEach(key => urlWithQuery.searchParams.append(key, params[key]));
  // }

  let urlWithQuery = url;

  if (params) {
    urlWithQuery = buildUrl(url, params);
  }

  const request = fetch(urlWithQuery, rest);
  return rawResponse ? request
    : request.then(async (resp) => {
      const data = await tryGetJson(resp);
      return {
        resp,
        data,
      };
    });
};

export default fetchWithParams;
