import { createAction } from 'redux-actions';
import {
  postData,
  getUserCourses,
} from '../api';

import {
  RECEIVE_CURRENT_USER_ID,
  RECEIVE_USER_DATA,
  POST_REQUEST_UNLOCK_LIMIT,
  POST_REQUEST_UNLOCK_LIMIT_SUCCESS,
  POST_REQUEST_UNLOCK_LIMIT_ERROR,
  RESET_POST_REQUEST_UNLOCK_LIMIT_ERROR,
  GET_USER_COURSES,
  GET_USER_COURSES_SUCCESS,
  GET_USER_COURSES_ERROR,
  GET_ENROLLED_USER_COURSES,
  GET_ENROLLED_USER_COURSES_SUCCESS,
  GET_ENROLLED_USER_COURSES_ERROR,

} from './constants';

const receiveCurrentUserId = res => ({ type: RECEIVE_CURRENT_USER_ID, res });

const receiveUserData = res => ({ type: RECEIVE_USER_DATA, res });

const postRequestUnlockLimit = () => ({ type: POST_REQUEST_UNLOCK_LIMIT });

const postRequestUnlockLimitSuccess = res => ({ type: POST_REQUEST_UNLOCK_LIMIT_SUCCESS, res });

const postRequestUnlockLimitError = res => ({ type: POST_REQUEST_UNLOCK_LIMIT_ERROR, res });

const resetPostRequestUnlockLimitError = () => ({ type: RESET_POST_REQUEST_UNLOCK_LIMIT_ERROR });

const fetchCourses = createAction(GET_USER_COURSES);
const fetchCoursesSuccess = createAction(GET_USER_COURSES_SUCCESS);
const fetchCoursesError = createAction(GET_USER_COURSES_ERROR);

const fetchEnrolledCourses = createAction(GET_ENROLLED_USER_COURSES);
const fetchEnrolledCoursesSuccess = createAction(GET_ENROLLED_USER_COURSES_SUCCESS);
const fetchEnrolledCoursesError = createAction(GET_ENROLLED_USER_COURSES_ERROR);

// upload true is used to fetch only user school courses
function retrieveUserCourses() {
  return function action(dispatch) {
    dispatch(fetchCourses());

    const request = getUserCourses({ upload: true });

    return request.then(
      res => dispatch(fetchCoursesSuccess(res.data)),
      () => dispatch(fetchCoursesError({ error: 'Something went wrong' })),
    );
  };
}

// upload false is used to fetch all enrolled user courses
function retrieveEnrolledUserCourses() {
  return function action(dispatch) {
    dispatch(fetchEnrolledCourses());

    const request = getUserCourses({ upload: false });

    return request.then(
      res => dispatch(fetchEnrolledCoursesSuccess(res.data)),
      () => dispatch(fetchEnrolledCoursesError({ error: 'Something went wrong' })),
    );
  };
}

function requestUnlockLimit() {
  return function action(dispatch) {
    dispatch(postRequestUnlockLimit());
    const request = postData('/users/request_unlock_limit');
    return request.then(
      () => dispatch(postRequestUnlockLimitSuccess()),
      () => dispatch(postRequestUnlockLimitError({ error: 'request unlock limit error' })),
    );
  };
}

export {
  receiveCurrentUserId,
  receiveUserData,
  requestUnlockLimit,
  resetPostRequestUnlockLimitError,
  retrieveUserCourses,
  retrieveEnrolledUserCourses,
};
