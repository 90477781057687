import querystring from 'qs';
import buildQueryObject from 'shared/helpers/utils/buildQueryObject';
import buildQueryString from 'shared/helpers/utils/buildQueryString';
import { isNil, getPathFromUrl } from 'shared/helpers/utils';
import urljoin from 'url-join';

export const buildUrl = (url, q, dv) => {
  try {
    if (isNil(url)) return url;

    const query = q || {};
    const defaultValues = dv || {};
    const urlParams = querystring.parse(url.split('?')[1]);
    const pathUrl = getPathFromUrl(url);
    const newQuery = buildQueryObject(urlParams, query, defaultValues);

    return urljoin(pathUrl, buildQueryString(newQuery));
  } catch (e) {
    console.error(e);
    return url;
  }
};

export default buildUrl;
