
const RECEIVE_PAGE_META = 'common/page/RECEIVE_PAGE_META';
const RECEIVE_PAGE_DATA = 'common/page/RECEIVE_PAGE_DATA';
const RECEIVE_PAGE_LIST = 'common/page/RECEIVE_PAGE_LIST';
const RECEIVE_PAGE_PROPS = 'common/page/RECEIVE_PAGE_PROPS';
const MERGE_PAGE_LISTS = 'common/page/MERGE_PAGE_LISTS';
const RECEIVE_PAGE_RELATIONSHIPS = 'common/page/RECEIVE_PAGE_RELATIONSHIPS';
const GET_PAGE_DATA = 'common/page/GET_PAGE_DATA';
const GET_PAGE_DATA_SUCCESS = 'common/page/GET_PAGE_DATA_SUCCESS';
const GET_PAGE_DATA_ERROR = 'common/page/GET_PAGE_DATA_ERROR';
const GET_DATA_BY_SECTION = 'common/page/GET_DATA_BY_SECTION';
const GET_DATA_BY_SECTION_SUCCESS = 'common/page/GET_DATA_BY_SECTION_SUCCESS';
const GET_DATA_BY_SECTION_ERROR = 'common/page/GET_DATA_BY_SECTION_ERROR';
const GET_PAGE_DATA_REDIRECT = 'common/page/GET_PAGE_DATA_REDIRECT';
const RESET_LOADING_PAGE_DATA_ERROR = 'common/page/RESET_LOADING_PAGE_DATA_ERROR';
const RECEIVE_SEARCH_QUERY = 'common/page/RECEIVE_SEARCH_QUERY';
const REMOVE_ENROLLMENT = 'common/page/REMOVE_ENROLLMENT';

const START_PAGE_SECTIONS_LOADING = 'common/page/START_PAGE_SECTIONS_LOADING';
const STOP_PAGE_SECTIONS_LOADING = 'common/page/STOP_PAGE_SECTIONS_LOADING';
const CLEAR_PAGE_SECTIONS = 'common/page/CLEAR_PAGE_SECTIONS';
const SET_PAGE_SECTIONS_TO_LOAD = 'common/page/SET_PAGE_SECTIONS_TO_LOAD';

const MATERIAL_TYPE_COUNT_DEFAULT_VALUE = 'all';
const MATERIAL_TYPE_COUNT_DEFAULT_TYPES = {
  LECTURES: 'All Lectures',
  CHAPTERS: 'All Chapters',
  EXAMS: 'All Exams',
};

export {
  RECEIVE_PAGE_META,
  RECEIVE_PAGE_DATA,
  RECEIVE_PAGE_LIST,
  RECEIVE_PAGE_PROPS,
  MERGE_PAGE_LISTS,
  RECEIVE_PAGE_RELATIONSHIPS,
  GET_PAGE_DATA,
  GET_PAGE_DATA_SUCCESS,
  GET_PAGE_DATA_ERROR,
  GET_DATA_BY_SECTION,
  GET_DATA_BY_SECTION_SUCCESS,
  GET_DATA_BY_SECTION_ERROR,
  GET_PAGE_DATA_REDIRECT,
  RESET_LOADING_PAGE_DATA_ERROR,
  MATERIAL_TYPE_COUNT_DEFAULT_VALUE,
  MATERIAL_TYPE_COUNT_DEFAULT_TYPES,
  RECEIVE_SEARCH_QUERY,
  REMOVE_ENROLLMENT,

  START_PAGE_SECTIONS_LOADING,
  STOP_PAGE_SECTIONS_LOADING,
  CLEAR_PAGE_SECTIONS,
  SET_PAGE_SECTIONS_TO_LOAD,
};
