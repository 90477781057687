import React, { PureComponent } from 'react';
import { string, oneOfType, number } from 'prop-types';
import { warnOnce, IS_DEV, createMarkup } from 'helpers/utils';

import icons from 'helpers/icons/icons.json';
import iconDecorator from './IconDecorator';

// Based on https://github.com/ianmiller347/feather-icons-react

const handleError = (err, props) => {
  warnOnce(err);
  if (IS_DEV) {
    /* eslint-disable */
    return <span className={props.className}>⚠️</span>;
    /* eslint-enable */
  }

  return null;
};

@iconDecorator()
export default class Icon extends PureComponent {
  static propTypes = {
    icon: string.isRequired,
    size: oneOfType([string, number]),
    width: oneOfType([string, number]),
    height: oneOfType([string, number]),
    className: string,
    title: string,
  };

  render() {
    const {
      icon,
      size,
      width,
      height,
      title,
      ...otherProps
    } = this.props;

    try {
      const [collection, iconName] = icon.split(':');
      const iconsData = icons[collection];

      if (iconsData && iconsData.icons[iconName]) {
        const { content, attr } = iconsData.icons[iconName];
        const {
          width: defaultWidth,
          height: defaultHeight,
          ...svgAttrs
        } = Object.assign({}, icons[collection].defaultAttrs, attr);

        if (content) {
          return (
            <svg
              width={width || size || defaultWidth}
              height={height || size || defaultHeight}
              {...svgAttrs}
              {...otherProps}
            >
              {
                title && (
                  <title>{title}</title>
                )
              }
              <g dangerouslySetInnerHTML={createMarkup(content)} />
            </svg>

          );
        }
      }

      throw new Error(`Icon is not defined: '${icon}'`);
    } catch (err) {
      return handleError(err, this.props);
    }
  }
}
