import React, { Component } from 'react';
import { bool } from 'prop-types';
import ReactDOM from 'react-dom';
import { isBrowser } from 'helpers/utils';

const WithPortal = ({ root }) => WrappedComponent =>
  class ModalPortal extends Component {
    static propTypes = {
      isDisplayed: bool,
    };

    constructor(props) {
      super(props);
      if (isBrowser) {
        this.el = document.createElement('div');
      }
    }


    componentDidMount() {
      if (this.el) {
        this.getRoot().appendChild(this.el);
      }
    }

    componentWillUnmount() {
      if (this.el) {
        this.getRoot().removeChild(this.el);
      }
    }

    // Accept DOM element or ID string
    getRoot = () => ((typeof root === 'string') ? document.getElementById(root) : root)

    render() {
      const {
        isDisplayed,
        ...restProps
      } = this.props;

      const modal = (
        <WrappedComponent {...restProps} />
      );

      return (isBrowser && isDisplayed) ? ReactDOM.createPortal(modal, this.el) : null;
    }
  };


export default WithPortal;
