import { withState, withHandlers, compose } from 'recompose';

export const withCustomToggle = (
  toggleName = 'toggledOn',
  toggleUpdaterName = 'toggle',
  initialToggle = false,
) => compose(
  withState(toggleName, toggleUpdaterName, initialToggle),
  withHandlers({
    show: ({ toggle }) => () => toggle(true),
    hide: ({ toggle }) => () => toggle(false),
    toggle: ({ toggle }) => () => toggle(current => !current),
  }),
);

const withToggle = withCustomToggle();

export default withToggle;
