import { ROUTES, ROUTES_BASE } from 'helpers/constants';
import { toPwaUrl } from 'helpers/pwa';
import buildUrl from 'helpers/utils/buildUrl';

const getAuthLink = ({
  withPwa = false,
  // pwaProps,
  intent = null,
  url,
  login,
  source,
  ...rest
} = {}) => {
  const baseUrl = url || (login ? ROUTES.login : ROUTES.signup);

  let { kind } = rest;
  let sourceProcessed = source;

  const path = (typeof window === 'undefined'
    ? global.INITIAL_PAGE_PATH
    : window.location.pathname) || '';

  if (source === undefined && path !== undefined) {
    if (path.startsWith(ROUTES_BASE.blog)) {
      sourceProcessed = 'blog';
    } else if (path.startsWith(ROUTES_BASE.boosterClass)) {
      sourceProcessed = 'booster';
    } else if (path.startsWith(ROUTES_BASE.homeworkHelp)) {
      sourceProcessed = 'hw';
    } else if (path.startsWith(ROUTES_BASE.livestream)) {
      sourceProcessed = 'livestream';
    } else if (path.startsWith(ROUTES_BASE.allMaterials)) {
      sourceProcessed = 'documents';
    } else if (path.startsWith(ROUTES_BASE.classNotes)) {
      sourceProcessed = 'documents';
    } else if (path.startsWith(ROUTES_BASE.studyGuides)) {
      sourceProcessed = 'documents';
    } else if (path.startsWith(ROUTES_BASE.textbookNotes)) {
      sourceProcessed = 'documents';
    }
  }

  if (kind === undefined && path !== undefined) {
    if (path.startsWith(ROUTES_BASE.classNotes)) {
      kind = 'cn';
    } else if (path.startsWith(ROUTES_BASE.studyGuides)) {
      kind = 'sg';
    } else if (path.startsWith(ROUTES_BASE.textbookNotes)) {
      kind = 'tn';
    }
  }

  // if (!sourceProcessed) {
  //   console.warn('Auth source is not defined');
  // }

  const query = {
    intent,
    source: sourceProcessed,
    kind,
    ...rest,
  };

  if (query.kind == null) {
    delete query.kind;
  }

  if (query.intent == null) {
    delete query.intent;
  }

  const result = buildUrl(baseUrl, query);
  return withPwa ? toPwaUrl({ url: result }) : result;
};

export default getAuthLink;
