import React from 'react';

const withContext = (Context, key) => Component =>
  props => (
    <Context.Consumer>
      {context => <Component {...props} {...{ [key]: context }} /> }
    </Context.Consumer>
  );

export default withContext;
