import {
  normalizeData, isEmpty, isFunction, isObject, deepGenerateIDs, generateIDsByPaths, sortObjectKeys, IS_DEV,
} from 'helpers/utils';
import removeEmptyFields from 'helpers/utils/removeEmptyFields';
import { createItem } from 'oc-core-components/src/Autocomplete/utils';
import shortid from 'shortid';

const getDataByType = (type, res, opts = {}) => {
  let result = {};
  if (res) {
    res.forEach((item) => {
      const d = normalizeData(item);

      const keys = Object.keys(d);

      keys.filter(k => (k === type)).forEach((key) => {
        result = Object.assign({}, result, d[key]);
      });
    });
  }

  // Sort attributes alphabetically on dev for readability in redux tools
  // TODO: Remove after keys filtering will be implemented in redux devtools extension
  // https://github.com/reduxjs/redux-devtools/issues/361#issuecomment-301063788
  if (IS_DEV) {
    result = Object.keys(result).reduce((acc, key) => {
      const obj = result[key];

      const newObj = {
        ...obj,
        attributes: sortObjectKeys(obj.attributes),
      };

      return { ...acc, [key]: newObj };
    }, {});
  }

  // Add unique ids to arrays inside the attributes object
  if (opts.withUniqueIds) {
    result = Object.keys(result).reduce((acc, key) => {
      const obj = result[key];
      let { attributes } = obj;

      if (isFunction(opts.preprocess)) {
        attributes = opts.preprocess(attributes);
      }

      const newObj = {
        ...obj,
        attributes: opts.paths
          ? generateIDsByPaths(attributes, opts.paths)
          : deepGenerateIDs(attributes),
      };

      return { ...acc, [key]: newObj };
    }, {});
  }

  return !isEmpty(result) ? result : null;
};

const sortByLabel = items => items.sort((a, b) => {
  const labelA = a.label ? a.label.toUpperCase() : 0;
  const labelB = b.label ? b.label.toUpperCase() : 0;
  if (labelA < labelB) return -1;
  if (labelA > labelB) return 1;
  return 0;
});

const getFilterRelationships = (type) => {
  let result = [];
  switch (type) {
    case 'school':
      result = ['country'];
      break;
    case 'department':
      result = ['school'];
      break;
    case 'professor':
      result = ['school', 'department', 'course'];
      break;
    default:
      break;
  }
  return result;
};

export const createFilterOption = (type, obj, useShortHandle) => {
  if (!isObject(obj?.attributes)) return null;

  const {
    id,
    code,
    name,
    url,
    shortHandle,
    tagNames,
  } = obj.attributes;

  let label = (type === 'course') ? code : name;

  if (useShortHandle && shortHandle) {
    label = shortHandle.toUpperCase();
  }

  return createItem(label, id || obj?.id, removeEmptyFields({
    type, url, shortHandle, name, tagNames,
  }));
};

const getFilterOptionsByType = ({
  data, type, ids, useShortHandle,
} = {}) => {
  let opts = [];
  if (data) {
    Object.keys(data).forEach((id) => {
      const obj = data[id];
      const {
        relationships,
      } = obj;
      const iRels = relationships;
      const validRels = [];
      let rels = [];

      if (ids && !isEmpty(ids)) {
        rels = getFilterRelationships(type);
        rels.filter((r) => {
          // its in the id array, and the item has a relation of this type
          const avail = (ids[`${r}Id`] && (iRels[r] && iRels[r].data));
          // the id in the array matches the relation id
          return (avail && (iRels[r].data.id === ids[`${r}Id`]));
        }).forEach(() => validRels.push(true));
      }


      if (validRels.length === rels.length) {
        opts.push(createFilterOption(type, obj, useShortHandle));
      }
    });
  }

  if (opts.length > 0) opts = sortByLabel(opts);
  // const empty = getFilterEmptyOption(type);
  // if (empty) opts.unshift(empty);

  return opts;
};

const getRelatedByType = (rel, data, type) => {
  let result = {};
  const related = rel[type];
  if (related) {
    data.forEach((o) => {
      const item = normalizeData(o);
      const keys = Object.keys(item);
      keys.forEach((key) => {
        const obj = item[key];
        if (key === type && obj[related.data.id]) {
          result = obj[related.data.id].attributes;
        }
      });
    });
  }
  return result;
};

const getDataWithRelationshipsByIdArray = (data, Ids) => {
  const result = [];
  if (Ids) {
    Ids.forEach((id) => {
      if (data[id] && data[id].attributes) {
        const item = { ...data[id].attributes };
        item.uid = item.id || shortid.generate();
        if (data[id].relationships) {
          const dataKeys = Object.keys(data[id].relationships);
          dataKeys.forEach((key) => {
            if (data[id].relationships[key].data && data[id].relationships[key].data.id) {
              item[`${key}Id`] = data[id].relationships[key].data.id;
            }
          });
        }
        result.push(item);
      }
    });
  }
  return result;
};

export const getTypeIdByRelation = (rel, relType) => {
  let result = null;
  if (rel) {
    const relatedKeys = Object.keys(rel).filter(type => type === relType);
    if (relatedKeys.length > 0 && rel[relType]?.data?.id) result = rel[relType].data.id;
  }
  return result;
};

export const getRelatedDocumentsByType = ({
  rel,
  docs,
  docRelType,
}) => {
  const result = [];
  const relatedKeys = rel ? Object.keys(rel).filter(type => type === docRelType) : [];

  let relationships = [];
  if (relatedKeys.length > 0) relationships = rel[relatedKeys[0]].data;

  if (relationships && docs) {
    const documents = relationships.filter(item => item.type === 'document');
    documents.forEach((doc) => {
      if (docs[doc.id] && docs[doc.id].attributes) result.push(docs[doc.id].attributes);
    });
  }

  return result;
};


export {
  getDataByType,
  getFilterOptionsByType,
  getRelatedByType,
  getDataWithRelationshipsByIdArray,
};
