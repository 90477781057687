import querystring from 'qs';
import removeEmptyFields from 'shared/helpers/utils/removeEmptyFields';

const buildQueryString = (queryObject, simpleString) => {
  try {
    const stringified = querystring.stringify(removeEmptyFields(queryObject), { arrayFormat: 'brackets', encodeValuesOnly: true });
    return stringified ? `${simpleString ? '' : '?'}${stringified}` : '';
  } catch (e) {
    console.error(e);
    return '';
  }
};

export default buildQueryString;
