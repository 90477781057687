// import { createSelector } from 'reselect';
import { selectV2AllowedActions } from 'v2/redux/page/selectors';
import { getV2PageSub } from 'v2/redux/selectors';
import { createSelector } from 'reselect';
import { camelizeKeys, isObject, isFunction } from 'shared/helpers/utils';
import { createItem } from 'oc-core-components/src/Autocomplete/utils';
import { EMPTY_SCHOOL_IDS } from 'helpers/constants';
import { getUserProfileLink } from 'common/user/utils';
// import createCachedSelector from 're-reselect';

export const getV2MetaSub = state => getV2PageSub(state).meta || {};
export const getV2CurrentUser = state => getV2MetaSub(state).currentUser || {};
export const getV2AllowHotjarTracking = state => getV2MetaSub(state).allowHotjarTracking;

const metaSelectorFactoryInner = (meta, key, proccess) => {
  try {
    if (meta && isObject(meta)) {
      return isFunction(proccess) ? proccess(meta[key]) : meta[key];
    }

    throw new Error('meta is not defined');
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

export const getV2MetaSelectorFactory = (key, cached, proccess) => {
  if (cached) {
    return createSelector(
      getV2MetaSub,
      pageProps => metaSelectorFactoryInner(pageProps, key, proccess),
    );
  }

  return state => metaSelectorFactoryInner(getV2MetaSub(state), key, proccess);
};

const getV2CurrentUserSelectorFactory = key => state => getV2CurrentUser(state)[key];

export const getV2Pagination = getV2MetaSelectorFactory('pagination');
export const getV2SearchQuery = getV2MetaSelectorFactory('query');
export const getV2IsLoggedIn = getV2MetaSelectorFactory('isLoggedIn');
export const getV2CountryId = getV2MetaSelectorFactory('countryId');
export const getV2CountryCode = getV2MetaSelectorFactory('countryCode');
export const getV2IsHumanUser = getV2MetaSelectorFactory('isHumanUser');
export const getV2CanonicalLink = getV2MetaSelectorFactory('canonicalLink');
export const getV2StripeApiKey = getV2MetaSelectorFactory('stripeApiKey');
export const getV2PageTitle = getV2MetaSelectorFactory('pageTitle');
export const getV2ItisFp = getV2MetaSelectorFactory('itIsFp');
export const getV2GoogleBotView = getV2MetaSelectorFactory('googleBotView');
export const getV2SubscribeBanner = getV2MetaSelectorFactory('subscribeBanner');
export const getV2ImpersonatingUserId = getV2MetaSelectorFactory('impersonatingUserId');
export const selectV2Breadcrumbs = getV2MetaSelectorFactory('breadcrumbs');
export const selectV2StreamActive = getV2MetaSelectorFactory('streamActive');
export const getV2IsIE11 = getV2MetaSelectorFactory('bestBrowser');
export const getV2TopBanner = getV2MetaSelectorFactory('topBanner');
export const getV2ProdBrowseCountryCode = getV2MetaSelectorFactory('prodBrowsecountryCode');
// actually this one isDisplaySummerChallengeBanner - but it's combined with data required for counter
export const getV2sCSpotsLeft = getV2MetaSelectorFactory('sCSpotsLeft');
export const getV2DashboardLivestreamBanner = getV2MetaSelectorFactory('dashboardLivestreamBanner');
export const getV2DisableSW = getV2MetaSelectorFactory('disableSw');
export const getV2FromPage = getV2MetaSelectorFactory('fromPage');
export const getV2CoreApiPrefix = getV2MetaSelectorFactory('coreApiPrefix');
export const getV2PrevUrl = getV2MetaSelectorFactory('prevUrl');
export const getV2GaEvents = getV2MetaSelectorFactory('gaEvents');
export const getV2TotalQuestionsCount = getV2MetaSelectorFactory('totalQuestionsCount');
export const getV2LoggedInPreviewLimit = getV2MetaSelectorFactory('loggedInPreviewLimit');
export const getV2Currency = getV2MetaSelectorFactory('currency');
export const getV2pageGtmAttrs = getV2MetaSelectorFactory('pageGtmAttrs');
export const getV2AskedQuestion = getV2MetaSelectorFactory('askedQuestion');
export const getV2IsCountryCodeEurope = getV2MetaSelectorFactory('isCountryCodeEurope');
export const getV2AboutStats = getV2MetaSelectorFactory('aboutStats');
export const getV2DiscordBanner = getV2MetaSelectorFactory('discordBanner');
export const getV2DisableComments = getV2MetaSelectorFactory('disableComments');
export const getV2PointsToCollect = getV2MetaSelectorFactory('pointsToCollect');
export const getV2ActiveProductCategory = getV2MetaSelectorFactory('activeProductCategory');
export const getV2RecentSearches = getV2MetaSelectorFactory('recentSearches');
export const getV2PromoPopup = getV2MetaSelectorFactory('promoPopup');
export const getV2NotificationMsg = getV2MetaSelectorFactory('notificationMsg');
export const getV2ShopCampaignItems = getV2MetaSelectorFactory('shopCampaignItems');
export const getV2WillEarnPoints = getV2MetaSelectorFactory('willEarnPoints', true, (data) => {
  try {
    if (!data || !isObject(data)) {
      return data;
    }

    return Object.entries(data).reduce((acc, [key, obj]) => ({
      ...acc,
      [key]: {
        ...obj,
        totalPoints: Object.values(obj).reduce((accSum, v) => accSum + v, 0),
      },
    }), {});
  } catch (e) {
    console.error(e);
    return data;
  }
});


export const getV2CurrentSubs = getV2MetaSelectorFactory('currentSubs', true, (subs) => {
  try {
    if (Array.isArray(subs)) {
      return subs.map(sub => (isObject(sub) ? camelizeKeys(sub) : sub));
    }

    return subs;
  } catch (e) {
    console.error(e);
    return subs;
  }
});

export const getV2VanillaLazy = state => getV2ItisFp(state) || getV2GoogleBotView(state);


// TODO: We need a better mechanism to get access to fields
export const getV2CurrentUserAvatar = getV2CurrentUserSelectorFactory('avatar');
export const getV2CurrentUserEnrollments = getV2CurrentUserSelectorFactory('enrollments');
export const getV2CurrentUserGraduationYear = getV2CurrentUserSelectorFactory('graduationYear');
export const getV2CurrentUserId = getV2CurrentUserSelectorFactory('id');
export const getV2CurrentUserContributorPointsCount = getV2CurrentUserSelectorFactory('contributorPointsCount');
export const getV2CurrentUserStatus = getV2CurrentUserSelectorFactory('status');
export const getV2CurrentUserSchoolId = getV2CurrentUserSelectorFactory('schoolId');
export const getV2CurrentUserSchoolName = getV2CurrentUserSelectorFactory('schoolName');
export const getV2CurrentUserKind = getV2CurrentUserSelectorFactory('kind');
export const getV2CurrentUserActivePro = getV2CurrentUserSelectorFactory('activePro');
export const getV2CurrentUserAllowUploadButtons = getV2CurrentUserSelectorFactory('allowUploadButtons');
export const getV2CurrentUserCreditCardDigits = getV2CurrentUserSelectorFactory('creditCardDigits');
export const getV2CurrentUserEmail = getV2CurrentUserSelectorFactory('email');
export const getV2CurrentUserFirstName = getV2CurrentUserSelectorFactory('firstName');
export const getV2CurrentUserLastName = getV2CurrentUserSelectorFactory('lastName');
export const getV2CurrentUserDisplayName = getV2CurrentUserSelectorFactory('displayName');
export const getV2CurrentUserQuestionTokensCount = getV2CurrentUserSelectorFactory('questionTokensCount');
export const getV2CurrentUserActiveProHw = getV2CurrentUserSelectorFactory('activeProHw');
export const getV2CurrentUserBalance = getV2CurrentUserSelectorFactory('balance');
export const getV2CurrentUserNickname = getV2CurrentUserSelectorFactory('nickname');
export const getV2CurrentUserSchoolCoursesCount = getV2CurrentUserSelectorFactory('schoolCoursesCount');
export const getV2CurrentUserHasProgram = getV2CurrentUserSelectorFactory('hasProgram');
export const getV2CurrentUserWeeklyPoints = getV2CurrentUserSelectorFactory('weeklyPoints');
export const getV2CurrentUserTotalPoints = getV2CurrentUserSelectorFactory('totalPoints');
export const getV2CurrentUserLevel = getV2CurrentUserSelectorFactory('level');
export const getV2CurrentUserPaypalEmail = getV2CurrentUserSelectorFactory('paypalEmail');

export const selectV2CurrentUserProfileLink = createSelector([
  getV2IsLoggedIn,
  getV2CurrentUserId,
  getV2CurrentUserNickname,
],
(isLoggedIn, userId, userNickname) => (isLoggedIn ? getUserProfileLink(userId, userNickname) : undefined));

export const selectV2CurrentUserFullName = createSelector([
  getV2CurrentUserFirstName,
  getV2CurrentUserLastName,
],
(firstName, lastName) => [firstName, lastName].join(' '));

export const selectV2CurrentUserSchoolOption = createSelector([
  getV2CurrentUserSchoolId,
  getV2CurrentUserSchoolName,
],
(schoolId, schoolName) => {
  const hasSchool = !!schoolId && !EMPTY_SCHOOL_IDS.includes(schoolId);

  if (hasSchool && schoolName) {
    return createItem(schoolName, schoolId);
  }

  return undefined;
});

// TODO: deprecated, use pageProps.allowedActions instead to control access to the content
export const getV2CurrentUserAllActiveProHw = (state) => {
  const allowedActions = selectV2AllowedActions(state);

  getV2CurrentUserActiveProHw(state) || allowedActions?.canUnlockWithSub;
};
