import React, { PureComponent } from 'react';
import {
  string, oneOf, bool, shape, node, oneOfType, number,
} from 'prop-types';
import cn from 'classnames';

import { warnOnce } from 'helpers/utils';

import withAmpStyles from 'helpers/withAmpStyles';
// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import { strokes, sizes, colors } from './styleConfig';
import stylesObj from './Icon.module.scss';
import ampStylesObj from './Icon.module.scss?amp&type=resolve';

const s = stylesProxy(stylesObj, 'Icon', { ignore: ['size', 'x-', 'y-'] });

const dimensionType = oneOfType([oneOf(sizes), number]);

const isNumber = v => (typeof v === 'number');
const isPercentage = v => (typeof v === 'string') && (v[v.length - 1] === '%') && isNumber(v.substring(0, v.length - 1));
const isNamedSize = v => v && !isNumber(v) && !isPercentage(v) && sizes.includes(v);

const Decorator = () => (DecoratedComponent) => {
  class Icon extends PureComponent {
    static propTypes = {
      'aria-label': string,
      className: string,
      svgClassName: string, // className for the decorated component
      wrapperProps: shape(), // props object for the wrapper
      stroke: oneOf(strokes), // svg stroke type modifier (e.g. none, inherit, etc)
      size: dimensionType, // icon size (width and height) (e.g. sm, lg)
      width: dimensionType, // icon width
      height: dimensionType, // icon height
      color: oneOf(colors), // icon theme color
      fill: oneOf(colors), // icon theme fill
      inline: bool, // inline icon
      tag: string,
      children: node,
      grayscale: bool,
    };

    static defaultProps = {
      tag: 'span',
    }

    render() {
      const {
        'aria-label': ariaLabel,
        className,
        svgClassName,
        wrapperProps,
        stroke,
        size,
        width,
        height,
        color,
        fill,
        inline,
        onClick,
        tag: Tag,
        children,
        icon,
        grayscale,
        styles,
        ...restProps
      } = this.props;

      const widthIsNumber = isNumber(width);
      const widthIsPercent = isPercentage(width);

      const heightIsNumber = isNumber(height);
      const heightIsPercent = isPercentage(height);

      const sizeIsNumber = isNumber(size);
      const sizeIsPercent = isPercentage(size);

      const classNames = cn(
        className,
        styles.icon,
        {
          [styles[color]]: color,
          [styles[`stroke-${stroke}`]]: stroke,
          [styles[`x-${width}`]]: isNamedSize(width),
          [styles[`y-${height}`]]: isNamedSize(height),
          [styles[`fill-${fill}`]]: !!fill,
          [styles[`size-${size}`]]: isNamedSize(size),
          [styles.inline]: inline,
          [styles.button]: Tag === 'button',
        },
      );

      if (Tag === 'button' && !ariaLabel) {
        warnOnce('ariaLabel is not defined');
      }

      return (
        <Tag
          className={classNames}
          onClick={onClick}
          aria-label={ariaLabel}

          {...wrapperProps}
        >
          <DecoratedComponent
            size={(sizeIsNumber || sizeIsPercent) ? size : undefined}
            width={(widthIsNumber || widthIsPercent) ? width : undefined}
            height={(heightIsNumber || heightIsPercent) ? height : undefined}
            icon={icon}
            data-icon-name={icon}
            className={cn(styles.svg, { [styles.grayscale]: grayscale }, svgClassName)}
            {...restProps}
          />
          {children}
        </Tag>
      );
    }
  }

  return withAmpStyles(Icon, s, stylesObj, ampStylesObj);
};

export default Decorator;
