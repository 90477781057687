import { pipe, path, defaultTo } from 'ramda';
import {
  string, arrayOf, shape, any, oneOfType, node, number,
} from 'prop-types';
import { isFunction, escapeString, isObject } from 'shared/helpers/utils';


export const translateItem = ({ label, value, ...rest }, ns, t) => ({ label: t(`${ns}:${label}`, rest), value });

export const translateItemsList = (items, ns, t) => items.map(item => translateItem(item, ns, t));


// export const translate = (item, t) => {
//   if (!isObject(item) || !isFunction(t) || !item) return item;

//   if (Array.isArray(item)) {
//     return item.map(i => translate(i, t));
//   }

//   return {
//     label: t(item?.label),
//     value: item?.value,
//   };
// };

export const itemToString = (item, t) => {
  const label = item && item.label;

  if (label) {
    return isFunction(t) ? t(label) : label;
  }

  return '';
};

export const itemToValue = pipe(path(['value']), defaultTo(null));

export const createItem = (label, value, props = {}, settings = {}) => {
  if (label === undefined || label === null) {
    return null;
  }

  let labelString = label && label.toString();

  if (settings.capitalizeLabel && labelString) {
    labelString = labelString.charAt(0).toUpperCase() + labelString.slice(1);
  }

  return {
    label: labelString,
    value,
    ...props,
  };
};

export const stringToItem = str => createItem(str, str);

export const arrayToItems = arr => arr.map(stringToItem);

export const getItemByValue = (items = [], val) => (items && items.find(item => item && item.value === val)) || null;


// TODO: Do not use
// export const itemFieldsToValue = (obj) => {
//   if (!isObject(obj)) return obj;

//   return Object.keys(obj).reduce((acc, key) => {
//     let val = obj[key];
//     val = (isObject(val)) ? itemToValue(val) : val;
//     return { ...acc, [key]: val };
//   }, {});
// };
// export const filterItems = memoize((inputValue, items) => items.filter(
//   i => !inputValue || itemToString(i).toLowerCase().includes(inputValue.toLowerCase()),
// ));

export function filterItems(inputValue, items = [], filter) {
  const reducer = (acc, item) => {
    // if the current item is a category obj
    if (item.options) {
      // filter Items inside the category
      const options = filterItems(inputValue, item.options, filter);

      const newItem = Object.assign({}, item, { options });

      return [...acc, newItem];
    } if (filter(inputValue, item)) {
      return [...acc, item];
    }

    return [...acc];
  };

  return items.reduce(reducer, []);
}

export const filterTagNames = (inputValue, { tagNames } = {}) => {
  if (tagNames && Array.isArray(tagNames)) {
    return tagNames.some(tag => tag.toLowerCase().includes(inputValue.toLowerCase()));
  }

  return false;
};

export const filterLabel = (inputValue, item) => `${itemToString(item)}`.toLowerCase().includes(inputValue.toLowerCase());

export const filterItem = (inputValue, item) => !inputValue || filterLabel(inputValue, item) || filterTagNames(inputValue, item);

export const itemPropTypes = shape({
  label: oneOfType([number, string, node]),
  value: any,
  tagNames: arrayOf(string),
  options: arrayOf(shape()),
});

export const itemsPropTypes = arrayOf(itemPropTypes);

export const highlightMatches = (item, pattern, className) => {
  const regexp = new RegExp(escapeString(pattern), 'ig');

  return item.replace(regexp, s => `<strong class="${className}">${s}</strong>`);
};


export const getListItemByValue = (value, list) => {
  if (!Array.isArray(list)) return null;

  const result = list.find(item => itemToValue(item) === value);

  return result || null;
};
