import React, { createContext, useContext } from 'react';
import getAuthLink from 'helpers/utils/getAuthLink';

import {
  node, shape, func, string,
} from 'prop-types';
import { withContext } from 'helpers/hoc';
import { warnOnce, toggleBodyClass } from 'helpers/utils';
import { formTypes } from 'oc-core-components/src/Authentication/constants';
import camelCase from 'lodash/camelCase';
import * as modals from './constants';

// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './Modal.module.scss';

const styles = stylesProxy(stylesObj, 'Modal');


const modalNames = Object.values(modals);

const generateModalFunctions = (thisObj, modalsMap = {}) => (
  Object.keys(modalsMap).reduce((acc, modalName) => {
    const key = camelCase(`open_${modalName.replace('_MODAL', '')}`);
    return {
      ...acc,
      [key]: props => thisObj.open(modalsMap[modalName], props),
    };
  }, {})
);
// Create context with default fields
export const ModalContext = createContext({
  activeModal: '',
  open: () => {},
  close: () => {},
  openLogin: () => {},
  openSignup: () => {},
  modalProps: {},
});

export const modalContextPropTypes = shape({
  activeModal: string,
  open: func,
  close: func,
  openLogin: func,
  openSignup: func,
  modalsMap: shape(),
  modalProps: shape(),
});

// Modal Consumer HOC
export const withModal = withContext(ModalContext, 'modal');

export const useModal = () => useContext(ModalContext);

export default class ModalProvider extends React.Component {
  static propTypes = {
    children: node,
  }
  /* eslint-disable react/no-unused-state, react/sort-comp */

  constructor(props) {
    super(props);


    this.state = {
      activeModal: '',
      open: this.open,
      close: this.close,
      openModal: this.openModal,
      modalProps: {},
      openLogin: this.openLogin,
      openSignup: this.openSignup,
      inline: this.inline,
      ...generateModalFunctions(this, {...modals, ...props.modalsMap}),
    };
  }

  openModal = (name, props = {}) => {
    this.setState({ activeModal: name, modalProps: props });

    toggleBodyClass(true, styles['modal-open']);
  }


  // open default modals (with name checking)
  open = (...args) => {
    const name = args[0];
    // TODO: HOTFIX redirect to signup page
    if(name === modals.AUTHENTICATION_MODAL) {
      const modalProps = args[1];

      if([formTypes.LOGIN, formTypes.SIGNUP].includes(modalProps?.activeForm)) {
        window.location.assign(getAuthLink({ intent: false, login: modalProps?.activeForm === formTypes.LOGIN }))
      }

      return;
    }


    if (!modalNames.includes(name)) {
      warnOnce(`Modal name is incorrect: ${name}`, true);
    }

    this.openModal(...args);
  }


  close = () => {
    this.setState({ activeModal: '', props: {} });
    toggleBodyClass(false, styles['modal-open']);
  }

  openLogin = (props) => {
    this.open(modals.AUTHENTICATION_MODAL, { activeForm: formTypes.LOGIN, ...props });
  }

  openSignup = (props) => {
    this.open(modals.AUTHENTICATION_MODAL, { activeForm: formTypes.SIGNUP, ...props });
  }

  inline = (props) => {
    this.open(modals.INLINE_MODAL, props);
  }

  /* eslint-enable react/no-unused-state */

  render() {
    const { children } = this.props;
    return (
      <ModalContext.Provider
        value={this.state}
      >
        {children}
      </ModalContext.Provider>
    );
  }
}
