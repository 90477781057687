import {
  shape,
  string,
  bool,
  number,
  node,
  oneOfType,
  func,
  arrayOf,
  oneOf,
  array as propTypesArray,
} from 'prop-types';
import { MODEL_TYPES } from 'v2/redux/constants';

import snakeCase from 'lodash/snakeCase';

import { isISODate } from 'helpers/utils';

export const avatarType = oneOfType([string, propTypesArray]);

export const ModelTypePropType = oneOf(MODEL_TYPES.map(t => snakeCase(t)));

export const numberString = oneOfType([string, number]);

export const uidType = numberString;

export const arrayItem = type => shape({
  uid: uidType,
  data: type,
});

export const array = type => arrayOf(arrayItem(type));


export const arrayShapeItem = obj => shape({
  uid: uidType,
  ...obj,
});

export const arrayOfShapes = obj => arrayOf(arrayShapeItem(obj));

export const breadcrumbType = arrayShapeItem({
  label: string,
  link: string,
  count: numberString,
  dataAttributes: shape(), // TODO: validation function for shape with dynamic keys
});

export const breadcrumbListType = arrayOf(breadcrumbType);

export const dropdownItemType = arrayShapeItem({
  value: node,
  label: node,
});

export const dropdownListType = arrayOf(dropdownItemType);

export const tagType = oneOfType([func, string]);

export const LinkListType = arrayOfShapes({
  label: string,
  url: string,
});


// TODO: finish prop types
export const documentType = shape({
  isPremiumDoc: bool,
  isStudyGuide: bool,
  kindNameFormatted: string,
  midterm: bool,
  name: string,
  pageCount: number,
  shortCourseCode: string,
  shortEnhanceDescription: string,
  term: string,
  textbookGuide: bool,
  thumbnailUrl: string,
  url: string,
  viewCount: number,
  studyGuideColor: string,
});

export const departmentType = shape({
  name: string,
});

export const courseType = shape({
  name: string,
});

export const liveTutorialType = shape();

export const liveTutorialSectionType = shape();

export const professorType = shape({
  name: string,
});


// Document Index => Top Shcools, Top Departments, etc,
export const topListsType = shape({
  school: LinkListType,
  department: LinkListType,
  course: LinkListType,
  professor: LinkListType,
});

export const metaType = shape({
  topLists: topListsType,
});

export const userAttrsType = shape({
  allowUploadButtons: bool,
});

export const userType = shape({
  displayName: string,
  id: number,
});

export const documentMetaType = shape({
  icon: string,
  label: string,
  link: string,
});

export const collegeDataValue = shape({
  data: string,
});

function isoStringTypeFactory(isRequired) {
  return function isoStringTypeValidator(props, propName, componentName) {
    const prop = props[propName];

    if (prop == null) {
      // Prop is missing
      if (isRequired) {
        // Prop is required but wasn't specified. Throw an error.
        throw new Error(`Failed prop type: The prop \`${propName}\` is marked as required in \`${componentName}\`, but its value is \`props[propName]\`.`);
      }
      // Prop is optional. Do nothing.
    } else if (!isISODate(prop)) {
      return new Error(
        `Invalid ISO Date String \`${propName}\` supplied to`
        + ` \`${componentName}\`. Validation failed.`,
      );
    }
  };
}

export const isoStringType = isoStringTypeFactory(false);

isoStringType.isRequired = isoStringTypeFactory(true);

export const paginationType = shape({
  currentPage: number,
  resultsCount: number,
  pagesCount: number,
});

export const paymentPlanType = shape({
  label: string,
  value: string,
  title: string,
  monthlyPrice: numberString,
  totalPrice: numberString,
  description: string,
  singleDocument: bool,
  tag: string,
  discount: numberString,
});

export const paymentPlansType = arrayOf(paymentPlanType);

export const renderNodeType = oneOfType([func, node]);
