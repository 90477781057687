// import axios from 'axios';

// import serializeError from 'serialize-error';
import urljoin from 'url-join';
import { memoize } from 'ramda';
import {
  isBrowser,
  IS_DEV,
  API_PORT,
  IS_LOCAL,
  ASSETS_URL,
  ELASTIC_SEARCH_URL,
} from 'helpers/utils';
import fetchWithParams from 'helpers/utils/fetchWithParams';
import buildUrl from 'helpers/utils/buildUrl';
import { RECOMMENDATION_KEY } from 'helpers/constants';
import aes from 'crypto-js/aes';
import kebabCase from 'lodash/kebabCase';

import getEnv from 'helpers/utils/getEnv';

import { V2_API_URLS } from './constants';

const env = getEnv();

// import qs from 'qs';

// TODO: refactor
// NOTE uncomment line 26 to test payments using NGROK proxy - ./ngrok http 3003
const host = (devPort = API_PORT, force) => {
  if (IS_DEV || IS_LOCAL) {
    return `http://localhost:${devPort}`;
    // TODO: comment out to test app with HTTPS using ngrok
    // return `https://7b8998c886d5.ngrok.io`;
  }
  let origin = isBrowser ? window.location.origin : env.PROD_URL;

  if (force) {
    origin += `:${devPort}`;
  }

  return origin;
};
// const host = (devPort = API_PORT) => (window.location.origin);

export const getUrl = () => (isBrowser && window.location) || null;

export const getHost = host;

export const getAssetsHost = () => ASSETS_URL || getHost();

export const withHost = (url = '', { hostPort, force } = {}) => urljoin(host(hostPort, force), url);


export const BASE_URL = getHost();

export const getFullUrl = (url = '') => ((url && (url.search('http') !== -1)) ? url : urljoin(BASE_URL, url));
// export const getFullUrl = (url = '') => (urljoin(BASE_URL, url));


// TODO: bug: default settings are ignored;
// axios.create({
//   baseURL: BASE_URL,
// });

// axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

export const fetchDefaultConfig = {
  mode: (IS_DEV || IS_LOCAL) ? 'cors' : 'same-origin',
  headers: { 'Content-Type': 'application/json' },
  credentials: (IS_DEV || IS_LOCAL) ? 'include' : 'same-origin',
};

export const postData = (url, { params, ...data } = {}, config = {}) => {
  const fetchData = {
    ...fetchDefaultConfig,
    method: 'post',
    params,
    body: config.useRawBody ? data?.rawBody : JSON.stringify(data),
    ...config,
  };

  if (!fetchData.headers) {
    fetchData.headers = {};
  }

  fetchData.headers['X-CSRF-TOKEN'] = window.OC_CSR_TOKEN;

  return fetchWithParams(
    getFullUrl(url),
    fetchData,
  );
};


// TODO: Deprecated; replace with getAPIData
// export const getData = (url, params) => axios({
//   method: 'get',
//   withCredentials: true,
//   url: getFullUrl(url),
//   ...params,
// });

export const getAPIData = ({ url, params = {}, ...config }) => fetchWithParams(
  getFullUrl(url),
  {
    ...fetchDefaultConfig,
    params,
    ...config,
  },
);


// TODO: Investigate tools for REST API doc generation, for example:
// http://apidocjs.com/
// https://swagger.io/


const notificationsEndpoint = '/api/unread_count';

// TODO: fix production co
// export const coursesEndpoint = urljoin(getAssetsHost(), IS_DEV ? 'api/dev_courses_p' : 'elastic_search/course');
// export const coursesEndpoint = urljoin(getAssetsHost(), (IS_DEV || IS_LOCAL) ? 'api/dev_courses_p' : 'elastic_search/course');
export const coursesEndpoint = urljoin(ELASTIC_SEARCH_URL, 'elastic_search/course');
export const schoolsEndpoint = urljoin(ELASTIC_SEARCH_URL, 'elastic_search/school');
export const blogSchoolsEndpoint = urljoin(ELASTIC_SEARCH_URL, 'elastic_search/blog_school');
export const departmentEndpoint = urljoin(ELASTIC_SEARCH_URL, 'elastic_search/department');
export const professorEndpoint = urljoin(ELASTIC_SEARCH_URL, 'elastic_search/professor');
export const textbookEndpoint = urljoin(ELASTIC_SEARCH_URL, 'elastic_search/book');
export const usersEndpoint = '/api/elastic_search/user';

// curried getAPIData and postData
export const get = url => params => getAPIData({ url, params });

const post = url => (data, config) => postData(url, data, config);
// POST

export const postCourseClick = data => postData('/notif_course_link_click', { params: data });

export const postDismissAskQuestionResultPopup = () => postData('/api/homework_help/dismiss_ask_popup');

export const postDocumentReferralClick = data => postData('/api/documents/create_referral_view', { params: data });

export const postCheckEmail = email => postData('/api/users/check_email.json', { email });

export const postSignup = post('/users/signup_slider.json');
export const postSignupStandAlone = post('/api/users/create_not_listed_user');
export const postPrefixedSignup = ({ coreApiPrefix = '/hw_api', ...data }) => postData(`${coreApiPrefix}/api/users/signup`, data);

export const postLogin = post('/sessions/create_from_popup.json');
export const postPrefixedLogin = ({ coreApiPrefix = '/hw_api', ...data }) => postData(`${coreApiPrefix}/api/users/login`, data);

export const postAccountDetail = post('/users/signup/set_year_school.json');

export const postAddCourses = post('/users/signup/finish_step2.json');

export const postResetPassword = post('/reset_passwords.json');


export const postCourses = (data, config) => {
  const {
    code,
    // eslint-disable-next-line camelcase
    school_id,
  } = data;

  return postData(urljoin(coursesEndpoint, `${code}_search`), { params: { school_id } }, { ...config, mode: 'cors' });
};

export const postUsers = (data, config) => {
  const {
    code,
    // eslint-disable-next-line camelcase
    school_id,
  } = data;

  return postData(urljoin(usersEndpoint, `${code}_search`), { params: { school_id } }, { ...config, mode: 'cors' });
};

export const postCoursesV2 = (data, config) => {
  const {
    code,
    // eslint-disable-next-line camelcase
    school_id,
  } = data;

  return postData(urljoin('/api/search/course/', code), { params: { school_id } }, { ...config, mode: 'cors' });
};

export const postSchools = (data, config) => {
  const {
    name,
    // eslint-disable-next-line camelcase
    country_id,
  } = data;

  return postData(urljoin(schoolsEndpoint, `${name}_search`), { params: { country_id } }, { ...config, mode: 'cors' });
};

// TODO: refactor SchoolAutocomplete
export const postSchoolsV2 = (data, config) => {
  const {
    name,
  } = data;

  return postData(urljoin('/api/search/school', name), {}, { ...config, mode: 'cors' });
};

export const postTextbooks = (data, config) => {
  const {
    name,
  } = data;

  return postData(urljoin(textbookEndpoint, `${name}_search`), { }, { ...config, mode: 'cors' });
};

// eslint-disable-next-line camelcase
export const postBlogSchools = ({ name, country_id, request_origin }, config) => (
  postData(urljoin(blogSchoolsEndpoint, `${name}_search`), { params: { country_id, request_origin } }, { ...config, mode: 'cors' })
);

export const postDepartments = (data, config) => {
  const {
    name,
    // eslint-disable-next-line camelcase
    school_id,
  } = data;

  return postData(urljoin(departmentEndpoint, `${name}_search`), { params: { school_id } }, { ...config, mode: 'cors' });
};

export const postProfessors = (data, config) => {
  const {
    name,
    // eslint-disable-next-line camelcase
    course_id,
  } = data;

  return postData(urljoin(professorEndpoint, `${name}_search`), { params: { course_id } }, { ...config, mode: 'cors' });
};

export const postPromoCode = params => post('/api/submit_new_promo')(params);

export const postFbErrors = post('/track_fb_blog_errors');

export const postCreateUserWithAdblock = () => postData('/adblock_detector/create_user_with_adblock', {});

export const postIntercomLeadId = visitorId => postData('/api/users/set_intercom_lead_id', { visitor_id: visitorId });

export const postAdblockDetect = action => postData('/adblock_detector/create_adblock_action.json', { u_action: action });

export const postUnlockDocuments = ({ coreApiPrefix = '', ...params }) => postData(`${coreApiPrefix}/api/react/payments/one_click_payment`, params);

export const postOneTimePurchase = ({ coreApiPrefix = '', ...params }) => postData(`${coreApiPrefix}/api/react/one_time_purchases`, params);

export const postSubscriptionVerification = params => postData('/api/subscriptions/create_from_stripe_source', params);

export const postChangePassword = params => postData('/reset_passwords/update_password', params);

export const postSwitchToOldUI = () => postData('/api/documents/switch_product_browse_ui');

export const postAhoyTrackCustom = params => postData('/ahoy_track_custom', params);

export const postEnrollToUniversity = schoolId => postData('/api/users/update_school', { school_id: schoolId });

export const postCreateWpUser = params => postData('/api/create_wp_user', params);

export const postCreateRequestNote = (documentId, userId, params) => postData('/api/documents/create_request_note', {
  document_id: documentId,
  note_taker_id: userId,
  ...params,
});


export const postRequestCourse = ({
  department,
  code,
  name,
  enrolled,
  year,
  semester,
  newForm,
} = {}) => (
  postData('/api/users/add_new_course', {
    department,
    c_code: code,
    c_name: name,
    currently_enrolled: enrolled,
    year,
    semester,
    new_form: newForm,
  })
);

// TODO: merge with postAddNewCourse
export const postAddNewCourses = ({
  from,
  courses,
}) => {
  const data = {
    from,
    time_table_course: courses,
  };

  return postData('/timetables/enroll_courses', data);
};

export const postEnrollCourse = (id, from) => postData('/timetables/enroll_single_course', {
  from,
  course_id: id,
});

export const postProgram = params => postData('/timetables/enroll_program_courses', params);
export const postComment = params => post('/api/blog/create_comment')(params);

export const postEditCourse = (enrollmentId, params) => postData(`/api/enrollments/${enrollmentId}/edit`, params);

export const postAssignSchool = params => post('/api/insights/assign_paypal_account')(params);

export const postQuestionCheckout = params => postData('/homework-help/create_single_payment', params);

// export const postQuestionPayWithCredit = id => postData(`/api/homework-help/${id}/pay_for_question_with_credit`);

export const postUnlockQuestion = (id, data) => postData(`/api/homework-help/${id}/unlock`, data);

export const postError = err => postData('/log_error', { err });

export const postCaptchaSuccess = (value, pageId) => postData('/api/user/captcha_success', { value, page_id: kebabCase(pageId) });

export const postSearchAttempt = params => postData('/api/search_attempt', params);

export const postParentsRequest = ({
  email, name, pageId, user_email,
}) => (
  postData('/api/users/request_parent_payment', {
    user_email,
    parent_email: email,
    first_name: name,
    // page_id: kebabCase(pageId),
    // This is the only supported value by backend so far (so other values are not working)
    page_id: 'homework_help',
  })
);

export const postCreateQuestion = data => (
  postData('/api/homework_help/create_question', { rawBody: data }, { useRawBody: true, headers: { } }) // pass empty headers to remove default content-type
);

export const postUpdateQuestion = (questionId, data) => (
  postData(`/api/homework_help/${questionId}/update_question`, { rawBody: data }, { useRawBody: true, headers: { } }) // pass empty headers to remove default content-type
);

export const postRequestNotes = data => postData('/api/notes_requests', data);
export const postBrowserFingerprint = data => postData('/api/user_browser_fingerprints', data);

export const postVerifyQuestion = (data, questionId) => postData(`/admin/homework_helps/${questionId}/accept_reject_question`, data);

export const postUpdatePwaPassword = data => postData('/api/pwa_settings/update_password', data);
export const postUpdatePwaProfile = data => postData('/api/pwa_settings/update_profile', data);

export const postAccountPersonalInfo = data => postData('/api/settings/account', { rawBody: data }, { useRawBody: true, headers: { } });

export const postAvatar = data => postData('/api/users/upload_avatar', { rawBody: data }, { useRawBody: true, headers: { } });


export const postCancelQuestion = data => postData('/api/homework_help/cancel_question', data);

export const postBcGroupForm = data => postData('/api/submit-bc-group-form', data);

export const postUpdateBcAnnotaion = data => postData('/api/booster-classes/update_annotation', data);

export const postDeleteBcAnnotaion = ({ id, content_only }) => postData('/api/booster-classes/delete_annotation', {
  id,
  content_only,
});

export const postBoosterClassComplete = ({
  course, skill, complete = true, type,
} = {}) => {
  if (!['theory', 'lecture'].includes(type)) {
    console.warn('incorrect bc complete type');
  }

  return postData(`/api/booster-classes/${course}/${skill}/${complete ? 'complete' : 'uncomplete'}_${type}`);
};

export const postExerciseStartSession = ({ courseHandle, skillHandle }, data) => (
  postData(`/api/booster-classes/${courseHandle}/${skillHandle}/exercises/start_session`, data)
);
export const postTestStartSession = ({ courseHandle, skillHandle }, data) => (
  postData(`/api/booster-classes/${courseHandle}/${skillHandle}/quizzes/start_session`, data)
);
export const postMidtermExamStartSession = ({ courseHandle }, data) => (
  postData(`/api/booster-classes/${courseHandle}/midterm/start_session`, data)
);
export const postFinalExamStartSession = ({ courseHandle }, data) => (
  postData(`/api/booster-classes/${courseHandle}/final/start_session`, data)
);
export const postExerciseContinueSession = ({
  sessionId,
}, data) => postData(`/api/booster-classes/practical/${sessionId}/continue_session`, data);

export const postPracticalRestartSession = ({ sessionId }, data) => (
  postData(`/api/booster-classes/practical/${sessionId}/restart_session`, {
    session_id: sessionId,
    ...data,
  })
);

export const postExerciseUserAnswer = ({
  sessionId,
}, data) => postData(`/api/booster-classes/practical/${sessionId}/submit_answer`, data);

export const postExerciseFeedback = ({
  sessionId,
}, data) => postData(`/api/booster-classes/practical/${sessionId}/submit_feedback`, data);

export const postCompletePracticalSession = ({
  sessionId,
}) => postData(`/api/booster-classes/practical/${sessionId}/complete_session`, { session_id: sessionId });

export const postCancelPracticalSession = ({
  sessionId,
  ...data
}) => postData(`/api/booster-classes/practical/${sessionId}/cancel_session`, { session_id: sessionId, ...data });

export const postHwAnswerFeedback = ({
  answerId,
  questionId,
  kind,
  feedback,
}) => postData(`/api/hw_answers/${answerId}/submit_answer_feedback`, { feedback_kind: feedback, kind, q_id: questionId });

export const postHwWatch = ({ questionId }) => postData(`/api/homework_help/${questionId}/watch`);
export const postHwUnwatch = ({ questionId }) => postData(`/api/homework_help/${questionId}/unwatch`);

export const postDocumentFeedback = ({
  documentId,
  kind,
  feedback,
  loggedOut,
}) => {
  // this documentId in payload looks a bit redundant, but Max told me to add it
  if (loggedOut) {
    return postData(`/api/documents/${documentId}/likes_dislikes/logout_create`, { kind, feedback_kind: feedback, document_id: documentId });
  }
  return postData(`/api/documents/${documentId}/likes_dislikes?kind=${kind}`, { feedback_kind: feedback, kind, document_id: documentId });
};

export const reportDocument = (documentId, values) => postData(`/api/documents/${documentId}/report`, { report: values });

export const postUserNotificationSettings = data => postData('/api/settings/notifications/update', data);

export const postEnrollmentToTcc = data => postData('/api/booster-classes/enrol', data);

export const postUpdatePaymentMethod = data => postData('/api/settings/billing', data);

export const postRemovePaymentMethod = () => postData('/api/settings/billing/delete-billing');


export const postTrackSubCancel = ({
  step,
  subscriptionId,
  cancellationId,
  ...restProps
}) => postData('/subscriptions/track_sub_cancel_popup', {
  step_number: step,
  subscription_id: subscriptionId,
  subscription_cancellation_id: cancellationId,
  ...restProps,
});

export const postDeleteSubscription = id => postData(`/api/subscriptions/${id}`, {}, { method: 'delete' });

export const postToggleWatchSearchQuery = ({ courseId, schoolId }, watch) => postData(`/api/users/${watch ? 'add' : 'remove'}_watcher`, {
  course_id: courseId,
  school_id: schoolId,
});

export const postPayForQuestion = id => postData(`/api/homework-help/${id}/pay_for_question`);

export const postSignupWithoutPassword = (data, coreApiPrefix) => postData(`${coreApiPrefix}/api/users/signup_without_password`, data);

export const postSendVoucher = (data, coreApiPrefix) => postData(`${coreApiPrefix}/api/vouchers/send`, data);

export const postSetUserSchool = ({ coreApiPrefix = '', ...params }) => postData(`${coreApiPrefix}/api/users/set_school`, params);

export const postSkipSetUserSchool = (coreApiPrefix = '') => postData(`${coreApiPrefix || ''}/api/users/skip_set_school`);

export const postFollowSubject = data => postData('/api/homework-help/follow_subject', data);
export const postUnfollowSubject = data => postData('/api/homework-help/unfollow_subject', data);

export const postFollowUser = data => postData('/api/create_new_follower', data);
export const postUnfollowUser = data => postData('/api/destroy_new_follower', data);

export const postTrackRelatedQuestion = ({
  type,
  landingType,
  landingId,
  id,
  orderNum,
}) => postData('/api/related_section/track', {
  obj_type: type,
  landing_type: landingType,
  obj_id: id,
  landing_id: landingId,
  ord_number: orderNum,
});


// HW answer enpoints

export const postInitHWAnswer = (questionId, config) => postData('/api/hw_answers/init_answer', { question_id: questionId }, config);

export const postHWAnswer = (answerId, { bodyHtml } = {}, config) => (
  postData(`/api/hw_answers/${answerId}/answer`, { body_html: bodyHtml }, config)
);

export const postHWAnswerDraft = (answerId, { bodyHtml } = {}, config) => (
  postData(`/api/hw_answers/${answerId}/save_answer_draft`, { body_html: bodyHtml }, config)
);

export const postRemoveHWAnswer = (answerId, config) => (
  postData(`/api/hw_answers/${answerId}/remove_answer`, config)
);

export const postSkipQuestion = data => (
  postData('/api/homework_help/skip_question', data)
);

export const postResendCouponDiscount = data => postData('api/resend_coupon_discount', data);
export const postChangeUserEmail = data => postData('/api/change_user_email', data);

export const postHWAnswerAttachments = (answerId, data, config) => (
  postData(`/api/hw_answers/${answerId}/save_answer_attachment`, { rawBody: data }, { useRawBody: true, headers: { }, ...config }) // pass empty headers to remove default content-type
);

export const postPaypalAccount = data => postData('/api/insights/assign_paypal_account', data);

export const postSummerChallengeRequest = data => postData('/api/summer_challenge_new_request', data);

export const postTrackSCEvents = data => postData('/api/tracking/summer_challenge', data);
export const postTrackHomefeed = data => postData('/api/homefeed_tracking', data);
export const postHideTopNotes = data => postData('/api/hide_dashboard_top_notes', data);


export const postShareUserChallengeSucces = type => (
  postData('/api/sc_get_user_data', {
    pp: new Date().getTime(),
    pass: '2nzEDzteBX9a',
    task_name: type,
  })
);

export const postCollectPoints = (type = 'daily_checkin') => (
  postData('api/users/collect_points', {
    type,
  })
);

export const postClaimPoints = () => postData('/api/users/claim_subscription');

export const postDeleteAllUploadErrors = () => postData('api/uploads/delete_all_error');

export const postDeleteDocUpload = documentId => postData('/api/uploads/delete_doc', { id: documentId });

export const postDeletePublishedDoc = documentId => postData('/api/uploads/delete_published_doc', { id: documentId });

export const postPublishDocUploads = docs => postData('/api/uploads/save_publish_docs', { docs_data: docs });

export const postUpdateDoc = data => postData('/api/documents/update_doc', data);

export const postCreateProfessor = ({ name, courseId, schoolId }) => postData('api/uploads/create_prof', { name, course_id: courseId, school_id: schoolId });

export const postCreateDocFromCloud = data => postData('api/uploads/create_document_from_cloud', data);

export const postVerifPhone = phone => postData('api/users/send_code', { phone });

export const postVerifCode = (code, isQuestion) => postData(isQuestion ? 'api/users/recheck_code_question' : 'api/users/recheck_code', { code });

export const postTrackShopProducts = (id, remove) => postData('/api/track_shop_products', { id, remove });

export const postTrackShopPopup = () => postData('/api/track_shop_popup');

export const postReferralEmail = email => postData('/api/referral_email', { email });

export const postCoverImg = img => postData('/api/settings/upload_cover_img', { cover_img: img });

export const postRemoveCoverImg = () => postData('/api/settings/remove_cover_img');

export const postCloseDashboardTopCourses = data => postData('api/users/close_dashboard_top_courses', data);

export const postUpdateDashSchool = data => postData('/api/users/update_dash_school', data);

export const postTrackBranchIo = data => postData('/api/track_branch_io', data);

export const postSubmitTiktokUsername = data => postData('/api/black-friday/submit_username', data);

export const postClickTracking = (page, link) => postData('/api/click_tracking', { page, link });

export const postTrackRefClick = (page, action) => postData('/api/tracking/ref_click', { page, action });

export const postTrackNtBanner = (page, action) => postData('api/tracking/nt_banner_doc_show', { page, action });


// GET

export const getRealDocumentViewCreate = documentId => get(`/api/documents/${documentId}/real_document_view_create`)();

export const getRemoveAllFromCourse = courseId => get('/api/enrollments/remove_all_from_course')({ course_id: courseId });

export const getPrevNextDocumentData = documentId => get(`/documents/${documentId}/get_prev_next_buttons.json`)();

export const getPrevNextDocumentTracking = ({
  isNext,
  currentDocumentId,
  destinationDocumentId,
}) => get(`/documents/${currentDocumentId}/track_prev_next_buttons.json`)({
  next: isNext,
  dest_doc_id: destinationDocumentId,
});

export const getMobileNavbarClick = get('/track_mobile_navbar_clicks');

export const getSearchSuggestions = (query = '', entity = '') => postData(urljoin('/elastic_search/autocomplete/', entity, `${query}_search`));
// Use this and React::SearchesController#autocomplete in the API to test in local.
// export const getSearchSuggestions = (query = '') => getAPIData({ url: `/api/search/autocomplete.en.html?query=${query}` });

export const getRelatedQuestions = (query = '') => getAPIData({ url: `/api/autocomplete?query=${query}` });

export const getNotifications = page => get(notificationsEndpoint)({ content: true, react: true, page });

export const getNotificationsCounter = get(notificationsEndpoint);

export const getRecommendedCourses = courseId => get('/users/get_course_recommend_list')({ course_id: courseId });

export const getUsers = ({ code }) => get(`${usersEndpoint}/${code}`)();

export const getCourseById = ({
  courseId,
  // authToken,
}) => {
  const requestUrl = V2_API_URLS.COURSES;
  let params;
  if (courseId) params = { id: courseId };
  // Tack on authToken if we have it.
  // if (authToken) params.authToken = authToken;
  return params ? getAPIData({ url: requestUrl, params }) : getAPIData({ url: requestUrl });
};

export const getDepartmentById = ({
  departmentId,
  // authToken,
}) => {
  const requestUrl = V2_API_URLS.DEPARTMENTS;
  let params;
  if (departmentId) params = { id: departmentId };
  // Tack on authToken if we have it.
  // if (authToken) params.authToken = authToken;
  return params ? getAPIData({ url: requestUrl, params }) : getAPIData({ url: requestUrl });
};

export const getProfessorById = ({
  professorId,
  // authToken,
}) => {
  const requestUrl = V2_API_URLS.PROFESSORS;
  let params;
  if (professorId) params = { id: professorId };
  // Tack on authToken if we have it.
  // if (authToken) params.authToken = authToken;
  return params ? getAPIData({ url: requestUrl, params }) : getAPIData({ url: requestUrl });
};

export const getSchoolById = ({
  schoolId,
  // authToken,
}) => {
  const requestUrl = V2_API_URLS.SCHOOLS;
  let params;
  if (schoolId) params = { id: schoolId };
  // Tack on authToken if we have it.
  // if (authToken) params.authToken = authToken;
  return params ? getAPIData({ url: requestUrl, params }) : getAPIData({ url: requestUrl });
};

export const getSchoolsByCountry = memoize(
  ({
    countryId,
    materialType,
    count = 'all',
    // authToken,
  }) => {
    const params = { countryId, count };
    if (materialType) params.product_type_url = materialType;
    // Tack on authToken if we have it.
    const requestUrl = `${V2_API_URLS.SCHOOLS}.json`;
    // if (authToken) requestUrl = `${requestUrl}?authToken=${authToken}`;

    return get(requestUrl)(params);
  },
);
// .then(({ data: { data } }) => data.map(({ attributes }) => ({ label: attributes.name, value: attributes.id })));

export const getCountriesByType = memoize(
  ({
    materialType,
    count = 'all',
    // authToken,
  }) => {
    const params = { count };
    if (materialType) params.product_type_url = materialType;
    // Tack on authToken if we have it.
    const requestUrl = `${V2_API_URLS.COUNTRIES}.json`;
    // if (authToken) requestUrl = `${requestUrl}?authToken=${authToken}`;
    return get(requestUrl)(params);
  },
);

export const getPreviewCounter = documentId => getAPIData({ url: `/api/documents/${documentId}/free_preview_counter.json` });

export const getDocumentChunk = (documentId, chunk, count) => (
  getAPIData({ url: '/api/documents/get_chunks_content.json', params: { doc_id: documentId, index: chunk, count } })
);

export const getRecommendations = (params = {}) => {
  const enc = aes.encrypt(params?.user_id?.toString(), RECOMMENDATION_KEY).toString();

  // https://github.com/notesolution/recommendation_interface/issues/1
  const url = buildUrl('https://rec-eng.oneclass.com/get_docs', {
    format: 'array',
    key: enc,
    ...params,
  });

  const resetOptions = {
    headers: undefined,
    mode: undefined,
    credentials: undefined,
  };

  // HTTP Long-polling
  getAPIData({
    url,
    rawResponse: true,
    ...resetOptions,
  });

  return getAPIData({
    url: `https://rec-eng.oneclass.com/sub/${enc}`,
    ...resetOptions,
  });
};

export const getRecRelatedQuestions = ({ questionId, topicId } = {}) => {
  const resetOptions = {
    headers: undefined,
    mode: undefined,
    credentials: undefined,
  };

  return getAPIData({
    url: `https://hw-rec-eng.oneclass.com/recommend/qid/${questionId}/tid/${topicId}`,
    ...resetOptions,
  });
};


export const getFreeTrialSchools = () => getAPIData({ url: '/api/gftsids.json' });

export const getUserCourses = ({ upload }) => get('/api/users/react_my_courses')({ upload });

export const getRedirectUrl = hashbang => get('/api/redirect_old_urls.json')({ url: hashbang });

export const getSaveGaClientId = clientId => get('/gcid.json')({ gcid: clientId });

export const getBlogNavbarSchools = get('/api/blog/get_schools_for_header');

export const getLtAttachmentFile = ({ sectionId, id }) => getAPIData({
  url: `/api/live_tutorial_sections/${sectionId}/download_attachment?attachment_id=${id}`,
  rawResponse: true,
});


export const getUpdateBookmark = ({ questionId }) => post('/api/homework-help/update_bookmark')({ id: questionId });

export const getBlogSuggestions = (query = '') => postData(urljoin(`/elastic_search/blog_school/${query}_search`), { mode: 'cors' });

export const getQuestionSuggestions = (query = '') => postData(urljoin(`/elastic_search/hw/${query}_search`), { mode: 'cors' });

export const getDefaultTermYear = schoolId => getAPIData({
  url: `/api/v2/schools/${schoolId}/current_year_term`,
});

export const getStreamData = get('/api/restream_stream_data');
export const getStreamAccessData = get('/api/restream_access_data');
export const getStudentsOnline = get('/api/users/online_stats');

export const getSkillSchedule = skillId => get('/api/booster-skills/availability_schedule')({ skill_id: skillId });

export const getInsightsChartData = data => get('/api/insights/chart_data')(data);

export const getEndSummerChallenge = () => get('/api/end_summer_challenge')();

export const getUserEnrollments = () => get('/api/user_courses')();

export const getSwitchSectionChapter = ({ kind, id } = {}) => get('api/homework-help/switch_section_chapter')({
  kind,
  id,
});

export const getHotCourses = data => get('/api/uploads/get_hot_courses')(data);

export const getSetDefaultBook = ({ bookId, bookBundleId } = {}) => get('/api/set_default_book')({
  book_bundle_id: bookBundleId,
  book_id: bookId,
});

export const getUserCourseAutocomplete = ({ code, userId, likes }) => get('/api/people/course_autocomplete')({ query: code, user_id: userId, likes });

export const getCoupon = data => get('/api/purchases/get_coupon')(data);
export const getInitSearchUsers = data => get('/api/search/init_users')(data);
export const getFollowers = data => get('/api/users/user_followers')(data);

// export const getDisableLivestream = () => getAPIData({
//   url: '/admin/homework_helps/stop_stream',
// });
