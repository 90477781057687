
// check generateModalFunctions when changing constants` names


export const ACTIVE_MODAL = 'activeModal';
export const ADD_COURSES_MODAL = 'addCourseModal';
export const ANSWER_POSTED_MODAL = 'answerPostedModal';
export const ASK_QUESTION_MODAL = 'askQuestionModal';
export const AUTHENTICATION_MODAL = 'authenticationModal';
export const CANCEL_SUBSCRIPTION_MODAL = 'cancelSubscriptionModal';
export const DOWNLOAD_LIMIT_MODAL = 'downloadLimitModal';
export const EDIT_COURSE_MODAL = 'editCourseModal';
export const EDIT_PAYMENT_METHOD_MODAL = 'editPaymentMethodModal';
export const REMOVE_PAYMENT_METHOD_MODAL = 'removePaymentMethodModal';
export const HELPFUL_MODAL = 'helpfulModal';
export const HW_AUTH_MODAL = 'hwAuthModal';
export const HW_EMAIL_PREVIEW_MODAL = 'hwEmailPreviewModal';
export const HW_PAYWALL_MODAL = 'HW_PAYWALL_MODAL';
export const LIMIT_REACHED_MODAL = 'limitReachedModal';
export const LIMITED_PREVIEW_MODAL = 'limitedPrevieModal';
export const PARENTS_EMAIL_SENT_MODAL = 'parentsEmailSentModal';
export const PAYWALL_MODAL = 'PAYWALL_MODAL';
export const PROMO_CODE_MODAL = 'promoCodeModal';
export const QUESTION_RECEIVED_MODAL = 'questionReceivedModal';
export const RATE_MODAL = 'rateThisCourse';
export const REGISTRATION_SUCCESS_MODAL = 'registrationSuccessModal';
export const REPORT_MODAL = 'reportThisDocument';
export const REQUEST_MODAL = 'requestMoreNotes';
export const SHARE_DOCUMENT_MODAL = 'shareDocumentModal';
export const SHARE_EMBED_MODAL = 'shareEmbedModal';
export const SHARE_LINK_MODAL = 'shareLinkModal';
export const SKILLS_PAYMENT_FORM_MODAL = 'skillsPaymentFormModal';
export const THANK_YOU_MODAL = 'thankYouModal';
export const TRIAL_FAILED_MODAL = 'trialFailedModal';
export const UNLOCK_MODAL = 'unlockDocument';
export const UNLOCK_MODAL_WITH_TOKEN = 'unlockDocumentWithToken';
export const UNLOCK_QUESTION_MODAL = 'unlockQuestionModal';
export const UNLOCK_THIS_ANSWER_MODAL = 'unlockThisAnswerModal';
export const UPLOAD_COURSE_MODAL = 'uploadCourseModal';
export const WECHAT_CHECKOUT_MODAL = 'wechatCheckoutModal';
export const PROFILE_MOBILE_FILTERS_POPUP = 'profileMobileFiltersPopup';
export const INSIGHTS_NAVIGATION_MODAL = 'insightsNavigationModal';


// Simple inline modal
export const INLINE_MODAL = 'inlineModal';
