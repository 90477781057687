import { ROUTES } from 'helpers/constants';
import { logError } from 'helpers/utils';
import buildUrl from 'helpers/utils/buildUrl';

import urljoin from 'url-join';
import {
  IN_UNIVERSITY,
  IN_HIGH_SCHOOL,
  NOT_IN_SCHOOL,
  SCHOOL_NOT_LISTED,
} from './constants';

export const getUserProfileLink = userId => (
  // eslint-disable-next-line
  userId ? urljoin(ROUTES.people, `${userId.toString()}.en.html`) : undefined);

export const getUserUploadsLink = (userId, params) => {
  const profileUrl = getUserProfileLink(userId);

  return profileUrl ? buildUrl(profileUrl, { upa: 'uploads', kind: 'published', ...params }) : undefined;
};

export const getUserUnlocksLink = (userId, params) => {
  const profileUrl = getUserProfileLink(userId);

  return profileUrl ? buildUrl(profileUrl, { upa: 'unlocks', ...params }) : undefined;
};

const noteTakerStatus = ['trial', 'assessment', 'hired'];

export const isNoteTaker = user => user && user.ntAccount && noteTakerStatus.includes(user.ntAccountStatus);

// [
//   ['University/College', 0],
//   ['High School',        1],
//   ['Not in School',      2],
//   ['School Not Listed',  3],
// ]
export const kindIsEqual = (kind, val) => {
  try {
    if (kind === undefined || kind === null) return false;

    const numericKind = parseInt(kind, 10);

    if (Number.isNaN(numericKind)) {
      logError('user kind is not correct', kind);
    }

    if (Array.isArray(val)) {
      return val.includes(numericKind);
    }

    return numericKind === val;
  } catch (e) {
    logError(e);
    return false;
  }
};

export const inUniversity = kind => kindIsEqual(kind, IN_UNIVERSITY);
export const inHighSchool = kind => kindIsEqual(kind, IN_HIGH_SCHOOL);
export const notInSchool = kind => kindIsEqual(kind, NOT_IN_SCHOOL);
export const schoolNotListed = kind => kindIsEqual(kind, SCHOOL_NOT_LISTED);

export default {};
