// import { getV2CurrentPage } from 'v2/redux/page/selectors';
// import { createSelector } from 'reselect';

export const selectV2LoadingObj = state => state.loading;
export const getLoadingById = (state, id) => state.loading[id];
export const getLoadingByIdProp = (state, { loadingId }) => getLoadingById(state, loadingId);

export const getHasLoading = (state, id) => typeof state.loading[id] === 'boolean';
export const getHasLoadingByIdProp = (state, { loadingId } = {}) => getHasLoading(state, loadingId);

// export const selectV2PageLoading = createSelector(
//   [selectV2LoadingObj, getV2CurrentPage],
//   (loadingObj, currentPage) => (loadingObj ? loadingObj[currentPage] : undefined),
// );

export default {};
