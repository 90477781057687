// import React, { useRef } from 'react';
import React from 'react';
// import { stylesProxy } from 'helpers/css';

// const disable = false;
// const force = true;

// const AmpComponent = ({
//   Component,
//   stylesObj,
//   ampStylesObj,
//   stylesProp,
//   global,
//   ...props
// }) => {
//   const { current: newStyles } = useRef(stylesProxy(
//     stylesObj,
//     '-',
//     {
//       styled: true,
//       className: global ? undefined : ampStylesObj?.className,
//     },
//     disable,
//     force,
//   ));

//   return (
//     <>
//       {/* <style jsx>{ampStylesObj}</style> */}
//       {/* <style jsx>{styles}</style> */}
//       {/* {styles} */}
//       <Component {...{ [stylesProp]: newStyles }} {...props} />
//       {
//         global ? (
//           <style jsx global>{ampStylesObj}</style>
//         ) : ampStylesObj.styles
//       }
//     </>
//   );
// };

const withAmpStyles = (Component, styles, stylesObj, ampStylesObj, options = {}) => {
  const Wrapper = (props) => {
    // const isAmp = useAmp();
    const stylesProp = options.stylesProp || 'styles';
    // const { global } = options;


    return (
      <Component {...{ [stylesProp]: styles }} {...props} />
    );
    // return isAmp ? (
    //   <AmpComponent
    //     Component={Component}
    //     stylesObj={stylesObj}
    //     ampStylesObj={ampStylesObj}
    //     stylesProp={stylesProp}
    //     global={global}
    //     {...props}
    //   />
    // ) : (
    //   <Component {...{ [stylesProp]: styles }} {...props} />
    // );
  };

  return Wrapper;
};

export default withAmpStyles;
