export const LOGIN = 'login';
export const SIGNUP = 'signup';
export const ACCOUNT_DETAILS = 'account_details';
export const ADD_COURSES = 'add_courses';
export const FORGOT_PASS = 'forgot_pass';

// We have to use "step*" cookie value for backward compatibility
// TODO: Replace 'step*' cookie values with form names after finishing react migration
export const formSignupSteps = {
  step1: SIGNUP,
  step2: ACCOUNT_DETAILS,
  step3: ADD_COURSES,
};

export const formTypes = {
  LOGIN,
  SIGNUP,
  ACCOUNT_DETAILS,
  ADD_COURSES,
  FORGOT_PASS,
};


// TODO: transform values to int
export const countriesMap = Object.freeze([
  {
    label: 'Canada',
    value: 1,
    shortLabel: 'CA',
  },
  {
    label: 'United States',
    value: 2,
    shortLabel: 'US',
  },
  {
    label: 'Australia',
    value: 3,
    shortLabel: 'AU',
  },
  {
    label: 'New Zealand',
    value: 4,
    shortLabel: 'NZ',
  },
  {
    label: 'United Kingdom',
    value: 5,
    shortLabel: 'UK',
  },
  {
    label: 'India',
    value: 6,
    shortLabel: 'IN',
  },
  {
    label: 'Philippines',
    value: 7,
    shortLabel: 'PH',
  },
]);

export const countriesShortMap = Object.freeze(countriesMap.map(({ label, shortLabel, ...rest }) => ({
  label: shortLabel,
  fullLabel: label,
  ...rest,
})));


// TODO: deprecated, replace with countryMap
export const countriesOptions = [
  {
    label: 'CA',
    value: '1',
    shortLabel: 'ca',
  },
  {
    label: 'US',
    value: '2',
    shortLabel: 'us',
  },
  {
    label: 'AU',
    value: '3',
    shortLabel: 'au',
  },
  {
    label: 'NZ',
    value: '4',
    shortLabel: 'nz',
  },
  {
    label: 'UK',
    value: '5',
    shortLabel: 'uk',
  },
  {
    label: 'IN',
    value: '6',
    shortLabel: 'in',
  },
  {
    label: 'PH',
    value: '7',
    shortLabel: 'ph',
  },
];

export const UNIVERSITY_STATUS = 1;
export const HIGH_SCHOOL_STATUS = 2;
export const LEARNER_STATUS = 3;

export const userStatusOptions = [
  {
    label: "I'm in college/university",
    value: UNIVERSITY_STATUS,
  },
  {
    label: "I'm in high school",
    value: HIGH_SCHOOL_STATUS,
  },
  {
    label: "I'm a lifelong learner",
    value: LEARNER_STATUS,
  },
];

export const yearGraduateOptions = [
  {
    label: 'Graduated',
    value: 'Graduated',
  },
  ...Array(5).fill().map((item, index) => {
    const year = (new Date().getFullYear() + index).toString();

    return {
      label: year,
      value: year,
    };
  }),
];

export const enrolledYearOptions = [
  ...Array(8).fill().map((item, index) => {
    const year = (new Date().getFullYear() - index).toString();

    return {
      label: year,
      value: year,
    };
  }),
];

// TODO: merge all semester constants

export const FALL_TERM = 'Fall';
export const WINTER_TERM = 'Winter';
export const SPRING_TERM = 'Spring';
export const SUMMER_TERM = 'Summer';

export const semesterOptions = [
  FALL_TERM,
  WINTER_TERM,
  SPRING_TERM,
  SUMMER_TERM,
].map(term => ({
  label: term,
  value: term.toLowerCase(),
}));
