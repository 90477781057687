// CSSModules HOC
import { IS_ONECLASS_PROD } from 'helpers/utils';

const stylesProxyChecker = (obj, prop, name, { styled, className, ignore = [] } = {}) => {
  const val = obj[prop];

  // patch getter for AMP styles
  // so that styles.container will return => "container jsx-12345"
  if (styled) {
    if (!(prop in Object.getOwnPropertyNames(obj)) && prop !== '__hash' && prop !== '$$typeof') {
      return className ? `${prop} ${className}` : prop;
    }
  }

  const ignoreProp = prop === 'null'
    || prop?.includes('undefined')
    || ignore.findIndex(substr => prop?.includes(substr)) !== -1;

  // @@toStringTag happens when an undefined prop is used with classnames.
  if (!val && prop && prop !== '@@toStringTag' && !ignoreProp) {
    console.error(`Styles are missing${name ? ` for ${name}` : ''}:`, prop);
  }

  return val;
};

export function stylesProxy(styles, name, opts, disable = false, force = false) {
  try {
    if (!force) {
      if (IS_ONECLASS_PROD || typeof Proxy === 'undefined' || disable) return styles;
    }

    return new Proxy(styles, {
      get: (obj, prop) => stylesProxyChecker(obj, prop, name, opts),
      getOwnPropertyDescriptor: (obj, ...args) => {
        stylesProxyChecker(obj, args[0], name, opts);
        return Object.prototype.hasOwnProperty.apply(obj, args);
      },
    });
  } catch (e) {
    console.error(e);

    return styles;
  }
}

export default {};
