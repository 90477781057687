import { createSelector } from 'reselect';
import {
  getV2PageSub, getV2UIDProp, getV2ModelIdProp, getV2DeviceType,
} from 'v2/redux/selectors';
import { getLoadingById } from 'v2/redux/loading/selectors';
import createCachedSelector from 're-reselect';
import { isObject, logErrorDev, isFunction } from 'helpers/utils';
import { isPwa } from 'helpers/pwa';


export const getV2CurrentPage = state => getV2PageSub(state).currentPage;
export const getV2IsSearchOverlayOpen = state => getV2PageSub(state).isSearchOverlayOpen;
export const getV2IsStudyModeOn = state => getV2PageSub(state).isStudyModeOn;
export const getV2IsStudyModeTooltipOpen = state => getV2PageSub(state).isStudyModeTooltipOpen;

export const getV2InitialPagePath = state => getV2PageSub(state).initialPagePath;

export const getV2LoadingPageList = state => getV2PageSub(state)?.loadingPageList;
export const getV2SectionLoading = state => getLoadingById(state, 'sections');
export const getV2PaymentPlanIdProp = (state, props) => props?.paymentFormId;
export const getV2SectionIdProp = (state, props) => props?.sectionId;
export const getV2AdvIdProp = (state, props) => props?.advId;

export const getV2PageProps = (state) => {
  const pageId = getV2CurrentPage(state);

  if (!pageId) {
    console.error('pageId is not defined');
    return undefined;
  }

  const pageData = getV2PageSub(state)[pageId];

  return pageData?.props;
};

const pagePropsSelectorFactoryInner = (pageProps, key, proccess) => {
  try {
    if (pageProps && isObject(pageProps)) {
      return isFunction(proccess) ? proccess(pageProps[key]) : pageProps[key];
    }

    throw new Error('page props are not defined');
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

export const pagePropsSelectorFactory = (key, cached, proccess) => {
  if (cached) {
    return createSelector(
      getV2PageProps,
      pageProps => pagePropsSelectorFactoryInner(pageProps, key, proccess),
    );
  }

  return state => pagePropsSelectorFactoryInner(getV2PageProps(state), key, proccess);
};


export const getV2PageUserId = pagePropsSelectorFactory('userId', true);
export const getV2PageBookId = pagePropsSelectorFactory('bookId', true);

export const getV2PageQuestionId = pagePropsSelectorFactory('questionId');

export const getV2PageAnswerDraftId = pagePropsSelectorFactory('draftId');

// export const getV2PagePropsModelId = MODEL_TYPES.reduce((acc, type) => ({
//   ...acc,
//   [type]: pagePropsSelectorFactory(`${type}Id`),
// }), {});


// export const selectV2ModelByPagePropsId = MODEL_TYPES.reduce((acc, type) => ({
//   ...acc,
//   [type]: createEntityByIdSelector(getV2PagePropsModelId[type], selectV2ModelsDataByType[type]),
// }), {});

// ============== PAGE FILTERS ==============

export const selectV2PageFilters = createSelector(
  getV2PageProps,
  pageProps => pageProps?.pageFilters,
);

// ============== PAGE LISTS ==============

// export const selectV2RawPageSections = createSelector(
//   getV2PageProps,
//   pageProps => pageProps?.pageLists,
// );

export const selectV2RawPageSections = pagePropsSelectorFactory('pageLists', true);

export const selectV2PageSections = createSelector(
  selectV2RawPageSections,
  pageSections => pageSections?.map(({ data = {}, viewAll = {}, ...rest }) => ({
    modelType: data?.type,
    modelId: data?.id,
    itemIds: data?.ids,
    viewAllLink: viewAll?.url,
    viewAllCount: viewAll?.count,
    viewAllName: viewAll?.name,
    ...rest,
  })),
);

export const selectV2PageSectionIndex = createCachedSelector(
  [
    selectV2RawPageSections,
    (state, sectionUid) => sectionUid,
  ],
  (pageSection, sectionUid) => (
    (pageSection && sectionUid !== undefined) ? pageSection.findIndex(({ uid }) => uid === sectionUid) : -1
  ),
)(
  (state, sectionUid) => sectionUid,
);

const pageSectionByIdInnerSelector = (pageSections, sectionId, itemsOnly) => {
  if (!sectionId) {
    logErrorDev('Section id is not defined for selectV2PageSectionById');
    return undefined;
  }

  const section = pageSections?.find(s => s?.uid === sectionId);

  if (!section) return undefined;

  return itemsOnly ? section?.itemIds : section;
};

export const selectV2PageSectionById = createCachedSelector(
  [
    selectV2PageSections,
    (state, sectionId) => sectionId,
    (state, sectionId, itemsOnly) => itemsOnly,
  ],
  pageSectionByIdInnerSelector,
)(
  (state, sectionId) => sectionId,
);

export const selectV2ModelIdByPageSectionId = createCachedSelector(
  [
    selectV2PageSectionById,
  ],
  section => section.modelId,
)(
  (state, sectionId) => sectionId,
);


export const selectV2PageSectionByIdProp = createCachedSelector(
  [
    selectV2PageSections,
    (state, props) => props?.sectionId,
  ],
  pageSectionByIdInnerSelector,
)(
  (state, props) => props?.sectionId,
);

export const selectV2PageSectionIdsByIdProp = createCachedSelector(
  [
    selectV2PageSectionByIdProp,
    (state, props, idsOnly) => idsOnly,
  ],
  (section, idsOnly) => {
    const ids = section?.itemIds;

    return idsOnly ? ids?.map(obj => (isObject(obj) ? obj.id : obj)) : ids;
  },
)(
  (state, props) => props?.sectionId,
);

export const selectV2Enrollments = createSelector(
  getV2PageProps,
  pageProps => pageProps?.enrollments,
);

// TODO: we need to normalize enrolments (build a course obj from courseId, courseName, and courseId and move it to common objects )
export const selectV2EnrollmentByCourseId = createCachedSelector(
  [
    getV2ModelIdProp.course,
    selectV2Enrollments,
  ],
  (courseId, enrollments) => {
    if (!Array.isArray(enrollments)) return null;
    return enrollments?.find(e => e.courseId === courseId);
  },
)(
  (state, props) => getV2ModelIdProp.course(state, props) || '_default',
);


export const selectV2PageSectionLoading = createCachedSelector(
  [getV2UIDProp, getV2LoadingPageList],
  (uid, loadingPageList) => (uid && isObject(loadingPageList) && loadingPageList[uid]) || false,
)(
  (state, props) => getV2UIDProp(state, props),
);


// PAYMENTS

/* TODO: consider better structure
  purchase: {
    isPurchased: getV2IsPurchased,
    error: getV2PaymentErrorMessage,
    plan: selectV2PurchasedPaymentPlan,
  }

  purchaseForm: {
    plans,
    oneTimePlan: selectV2PaymentPlan,
  }

  user: {
    isNew: getV2IsNewUser,
    password: getV2UserPassword,
  }
*/


export const getV2IsNewUser = pagePropsSelectorFactory('newUser');
export const getV2IsPurchased = pagePropsSelectorFactory('isPurchased');
export const getV2PaymentErrorMessage = pagePropsSelectorFactory('paymentErrorMessage');
export const getV2UserPassword = pagePropsSelectorFactory('userPassword');
export const getV2UserPaymentPlans = pagePropsSelectorFactory('userPaymentPlans');
export const selectV2PaymentPlan = pagePropsSelectorFactory('paymentPlan', true);
export const selectV2PurchasedPaymentPlan = pagePropsSelectorFactory('purchasedPaymentPlan', true);
export const selectV2CompletedOneTimePurchase = pagePropsSelectorFactory('completedOneTimePurchase', true);
export const selectV2PurchasedTrackingAttrs = pagePropsSelectorFactory('purchasedTrackingAttrs', true);
export const getV2PaymentPlans = pagePropsSelectorFactory('paymentPlans', true);
export const selectV2PageQuestionId = pagePropsSelectorFactory('questionId', true);
export const selectV2PageSubscriptionId = pagePropsSelectorFactory('subscriptionId', true);
export const selectV2PageAdv = pagePropsSelectorFactory('adv', true);
export const selectV2PreviousUrl = pagePropsSelectorFactory('previousUrl', true);
export const selectV2HwAdminLoggedIn = pagePropsSelectorFactory('hwAdminLoggedIn', true);
export const selectV2BackBtnUrl = pagePropsSelectorFactory('backBtnUrl');
export const selectV2BackBtnText = pagePropsSelectorFactory('backBtnText');
export const selectV2ErrorMessage = pagePropsSelectorFactory('errorMessage');
export const selectV2PageStreamTutorId = pagePropsSelectorFactory('streamTutorId');

export const selectV2SearchPageCounters = pagePropsSelectorFactory('productsCounters', true);

export const selectV2SearchPageCountersById = createSelector(
  [
    selectV2SearchPageCounters,
    (_, id) => id,
  ],
  (counters, id) => (isObject(counters) ? counters[id] : undefined),
);

// TODO: move to corresponding pages files
export const selectV2bcExerciseQuestionIndex = pagePropsSelectorFactory('qIndex', true);
export const selectV2bcExerciseSessionResults = pagePropsSelectorFactory('sessionResults', true);
export const selectV2bcFeedbackData = pagePropsSelectorFactory('feedbackData', true);
export const selectV2hwTotalViewsCount = pagePropsSelectorFactory('totalViewsCount', true);
export const selectV2hwWatchedByCurrentUser = pagePropsSelectorFactory('watchedByCurrentUser');
export const selectV2hwFeedbackOptions = pagePropsSelectorFactory('feedbackOptions', true);

export const selectV2hwLikeKind = pagePropsSelectorFactory('likeKind', true);
export const selectV2hwLikesCount = pagePropsSelectorFactory('likesCount', true);
export const selectV2hwDislikeKind = pagePropsSelectorFactory('dislikeKind', true);
export const selectV2hwDislikesCount = pagePropsSelectorFactory('dislikesCount', true);
export const selectV2DocumentFeedback = pagePropsSelectorFactory('documentFeedback', true);

export const selectV2PaymentForm = pagePropsSelectorFactory('paymentForm', true);
export const selectV2AllowedActions = pagePropsSelectorFactory('allowedActions', true);
export const selectV2Invoice = pagePropsSelectorFactory('invoice', true);
export const selectV2SidedbarData = pagePropsSelectorFactory('sidebarData', true);
export const selectV2LandingUrl = pagePropsSelectorFactory('landingUrl', true);
export const selectV2ExercisesUrl = pagePropsSelectorFactory('exercisesIndexUrl', true);
export const selectV2QuizzesUrl = pagePropsSelectorFactory('quizzesIndexUrl', true);
export const selectV2MidtermExamIndexUrl = pagePropsSelectorFactory('midtermExamIndexUrl', true);
export const selectV2FinalExamIndexUrl = pagePropsSelectorFactory('finalExamIndexUrl', true);
export const selectV2ShowStartBtn = pagePropsSelectorFactory('showStartBtn', true);
export const selectV2acPage = pagePropsSelectorFactory('acPage', true);
export const selectV2IsUpgradePage = pagePropsSelectorFactory('isUpgrade', true);
export const selectV2PurchaseType = pagePropsSelectorFactory('purchaseType', true);
export const getV2PaymentMethods = pagePropsSelectorFactory('paymentMethods', true);
export const selectV2ProductLines = pagePropsSelectorFactory('productLines');
export const selectV2ExamInfo = pagePropsSelectorFactory('examInfo');
export const selectV2NumQuestionsInSession = pagePropsSelectorFactory('numQuestionsInSession');
export const selectV2WatchedSearch = pagePropsSelectorFactory('watched');
export const selectV2PaymentOptions = pagePropsSelectorFactory('paymentOptions');
export const selectV2BoosterRegularPrice = pagePropsSelectorFactory('boosterRegularPrice');
export const selectV2VoucherCode = pagePropsSelectorFactory('voucherCode');
export const selectV2ChartData = pagePropsSelectorFactory('chartData');
export const selectV2PaypalAccountEmail = pagePropsSelectorFactory('paypalAccountEmail');
export const selectV2PaypalBalance = pagePropsSelectorFactory('paypalBalance');
export const selectV2ViewsCount = pagePropsSelectorFactory('viewsCount');
export const selectV2RevenueAmount = pagePropsSelectorFactory('revenueAmount');
export const selectV2NextPayDayDate = pagePropsSelectorFactory('nextPayDayDate');
export const selectV2LatestContributionId = pagePropsSelectorFactory('latestContributionId');
export const selectV2LatestContributionType = pagePropsSelectorFactory('latestContributionType');
export const selectV2TotalPayoutAmount = pagePropsSelectorFactory('totalPayoutAmount');
export const selectV2TotalAnswersCount = pagePropsSelectorFactory('totalAnswersCount');
export const selectV2ShowAnswerForm = pagePropsSelectorFactory('showAnswerForm');
export const selectV2UserHasAnswered = pagePropsSelectorFactory('userHasAnswered');
export const selectV2UserAnswerIds = pagePropsSelectorFactory('userAnswerIds');
export const selectV2ObjectId = pagePropsSelectorFactory('objectId');
export const selectV2SchoolId = pagePropsSelectorFactory('schoolId');
export const selectV2PageType = pagePropsSelectorFactory('pageType');
export const selectV2BlogArticleId = pagePropsSelectorFactory('blogArticleId');
export const selectV2ScrollToComments = pagePropsSelectorFactory('scrollToComments');
export const selectV2ZoneCategory = pagePropsSelectorFactory('zoneCategory');
export const selectV2ZoneCategoryDescription = pagePropsSelectorFactory('zoneCategoryDescription');
export const selectV2Topic = pagePropsSelectorFactory('topic');
export const selectV2ObjectType = pagePropsSelectorFactory('objectType');
export const selectV2totalMyAnswersCount = pagePropsSelectorFactory('totalMyAnswersCount');
export const selectV2SummerChallenge = pagePropsSelectorFactory('summerChallenge');
export const selectV2DailyCheckin = pagePropsSelectorFactory('dailyCheckin');
export const selectV2UserCurrentPosition = pagePropsSelectorFactory('currentPosition');
export const selectV2UserActivePointsSub = pagePropsSelectorFactory('activePointsSub');
export const selectV2HasActivePSub = pagePropsSelectorFactory('activePSub');
export const selectV2AllowStartSubscription = pagePropsSelectorFactory('allowStartSubscription', true);

// export const selectV2ExamInfo = pagePropsSelectorFactory('examInfo');
// export const selectV2ExamInfo = pagePropsSelectorFactory('examInfo');
export const selectV2SelectedVoucher = pagePropsSelectorFactory('selectedVoucher');
export const selectV2PurchasedVoucher = pagePropsSelectorFactory('purchasedVoucher');
export const selectV2CanEnrollForFree = pagePropsSelectorFactory('canEnrollForFree');
export const selectV2TotalCounters = pagePropsSelectorFactory('totalCounters');
export const selectV2KindCounters = pagePropsSelectorFactory('kindCounters');
export const selectV2AnswersCount = pagePropsSelectorFactory('answersCount');
export const selectV2UploadsCount = pagePropsSelectorFactory('uploadsCount');
export const selectV2UnlocksCount = pagePropsSelectorFactory('unlocksCount');
export const selectV2WatchingCount = pagePropsSelectorFactory('watchingCount');
export const selectV2QuestionsCount = pagePropsSelectorFactory('questionsCount');
export const selectV2ProfileOwner = pagePropsSelectorFactory('profileOwner');
export const selectV2HideAnswer = pagePropsSelectorFactory('hideAnswer');
export const selectV2PageCourses = pagePropsSelectorFactory('courses');
export const selectV2XLinkUrls = pagePropsSelectorFactory('xLinkUrls');
export const selectV2SubscribersCount = pagePropsSelectorFactory('subscribersCount');
export const selectV2RatingAmount = pagePropsSelectorFactory('ratingAmount');
export const selectV2IsTutor = pagePropsSelectorFactory('isTutor');
export const selectV2TutorFirstContribution = pagePropsSelectorFactory('tutorFirstContribution');
export const selectV2TopSchools = pagePropsSelectorFactory('topSchools');
export const selectV2AllTopics = pagePropsSelectorFactory('allTopics');
export const selectV2AllowFreeHw = pagePropsSelectorFactory('allowFreeHw');
export const selectV2LatestEnrollmentIds = pagePropsSelectorFactory('latestEnrollmentIds');
export const selectV2ShowEmailSubscribe = pagePropsSelectorFactory('showEmailSubscribe');
export const selectV2HidePaypalPayments = pagePropsSelectorFactory('hidePaypalPayment');
export const selectV2CoreTopic = pagePropsSelectorFactory('coreTopic');
export const selectV2IsUnlocked = pagePropsSelectorFactory('isUnlocked');
export const selectV2ReferralData = pagePropsSelectorFactory('referralData');
export const selectV2DocsCount = pagePropsSelectorFactory('docsCount');
export const selectV2ReferralBanner = pagePropsSelectorFactory('referralBanner');
export const selectV2ShowTopCourses = pagePropsSelectorFactory('showTopCourses');
export const selectV2HotCourses = pagePropsSelectorFactory('hotCourses', true);
export const selectV2DeepLink = pagePropsSelectorFactory('deepLink');
export const selectV2ShowHotCourseBanner = pagePropsSelectorFactory('showHotCourseBanner');
export const selectV2SelectedTab = pagePropsSelectorFactory('selectedTab');
export const selectV2CurrentPosition = pagePropsSelectorFactory('currentPosition');
export const selectV2TotalAmount = pagePropsSelectorFactory('totalAmount');
export const selectV2EarningPercent = pagePropsSelectorFactory('earningPercent');
export const selectV2LastUpdate = pagePropsSelectorFactory('lastUpdate');
export const selectV2DaysLeft = pagePropsSelectorFactory('daysLeft');
export const selectV2CampaignStart = pagePropsSelectorFactory('campaignStart');
export const selectV2CampaignEnds = pagePropsSelectorFactory('campaignEnds');
export const selectV2CampaignDraw = pagePropsSelectorFactory('campaignDraw');
export const selectV2UserEarningData = pagePropsSelectorFactory('userEarningData');
export const selectV2EarnedSteps = pagePropsSelectorFactory('earnedSteps');
export const selectV2UserFeedback = pagePropsSelectorFactory('userFeedback');
export const selectV2OriginalPaymentForm = pagePropsSelectorFactory('originalPaymentForm');
export const selectV2Pagination = pagePropsSelectorFactory('pagination');


// TODO: consider cached selector or update backend
export const selectV2PaymentMethods = createSelector(
  [getV2PaymentMethods],
  items => items?.data.map(i => i.attributes),
);

// TODO: move to meta
export const selectV2DisplayCrisp = pagePropsSelectorFactory('displayCrisp', true);


export const selectV2HighlightUnlockQuestionBtn = pagePropsSelectorFactory('highlightUnlockQuestionBtn', true);
export const selectV2RawPaymentPlans = pagePropsSelectorFactory('paymentPlans', true);

const flattenPaymentPlansObj = (paymentPlans) => {
  if (Array.isArray(paymentPlans)) {
    return paymentPlans;
  // when paymentPlans is an Object
  } if (isObject(paymentPlans)) {
    const array = Object.values(paymentPlans)?.flat();

    return array;
  }
  return undefined;
};

export const selectV2PaymentPlans = createSelector(
  [getV2PaymentPlans],
  paymentPlans => flattenPaymentPlansObj(paymentPlans),
);

export const selectV2PaginationByIdProp = createCachedSelector(
  [selectV2Pagination, getV2SectionIdProp],
  (pagination, id) => {
    if (!(pagination && id)) return undefined;

    const paginationObj = pagination[id];

    return paginationObj;
  },
)(
  (state, props) => getV2SectionIdProp(state, props) || '_default',
);

export const selectV2PaymentPlansByIdProp = createCachedSelector(
  [selectV2PaymentForm, getV2PaymentPlanIdProp],
  (paymentForm, id) => {
    if (!(paymentForm && id)) return undefined;

    const paymentPlans = paymentForm[id]?.paymentPlans;

    return flattenPaymentPlansObj(paymentPlans);
  },
)(
  (state, props) => getV2PaymentPlanIdProp(state, props) || '_default',
);
export const selectV2PaymentPlanByIdProp = createCachedSelector(
  [selectV2PaymentForm, getV2PaymentPlanIdProp],
  (paymentForm, id) => ((paymentForm && id) ? paymentForm[id]?.paymentPlan : undefined),
)(
  (state, props) => getV2PaymentPlanIdProp(state, props) || '_default',
);

export const selectV2PageAdvByIdProp = createCachedSelector(
  [selectV2PageAdv, getV2AdvIdProp, getV2DeviceType],
  (adv, id, deviceType) => {
    if (!adv) return undefined;
    const obj = adv[id];
    if (!obj) return undefined;

    const keys = Object.keys(obj);

    if (keys.includes('desktop') || keys.includes('mobile')) {
      return obj[deviceType];
    }

    return obj;
  },
)(
  (state, props) => getV2AdvIdProp(state, props) || '_default',
);

export const selectV2IsPwaPage = createSelector(
  [getV2InitialPagePath],
  path => isPwa(path),
);

export const selectV2ABTestByName = createCachedSelector(
  [
    getV2PageProps,
    (state, name) => name,
  ],
  (pageProps, testName) => {
    const abMap = pageProps?.abTests;

    if (abMap && testName) {
      return abMap[testName];
    }

    return undefined;
  },
)(
  (state, testName) => testName || '_default',
);

export const selectV2ActiveABTest = createSelector(
  [
    getV2PageProps,
  ],
  (pageProps) => {
    const abMap = pageProps?.abTests;

    if (abMap) {
      const activeTest = Object.entries(abMap).find(([key, value]) => value);
      return activeTest ? activeTest[0] : undefined;
    }

    return undefined;
  },
);
