const buildQueryObject = (
  currentQuery = {},
  changes = {},
  defaultValues = {},
) => Object.entries({ ...currentQuery, ...changes })
  .reduce((acc, [key, value]) => {
    if (value !== defaultValues[key]) {
      return { ...acc, [key]: value };
    }
    return acc;
  }, {});

export default buildQueryObject;
