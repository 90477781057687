import NextI18Next from 'next-i18next';
import getEnv from 'shared/helpers/utils/getEnv';
import { numberWithCommas, ordinal } from 'shared/helpers/utils';

const env = getEnv();

const options = {
  defaultLanguage: 'en',
  otherLanguages: ['en'],
  fallbackLng: 'en',
  ...env.NODE_ENV === 'production' && { localePath: '../shared/static/locales' },
  // to move locales to concrete next.js app consider this option
  // https://github.com/isaachinman/next-i18next/issues/761
  // localePath: typeof window === 'undefined' ? `../shared/static/locales` : 'host_name/ns',
  debug: env.I18N_DEBUG,
  saveMissing: false,
  initImmediate: true,
  parseMissingKeyHandler: (key) => {
    if (env.NODE_ENV === 'production' && !env.FEATURE) {
      // TODO: add sentry catching
      return null;
    }
    console.error('Missing translation:', key);
    return key;
  },
  interpolation: {
    escapeValue: false,
    formatSeparator: ',',
    format(value, format, lng) {
      if (value) {
        if (format === 'uppercase') return value.toUpperCase();

        if (format === 'lowercase') return value.toLowerCase();

        if (format === 'numberWithCommas') return numberWithCommas(value);

        if (format === 'ordinal') return ordinal(value);
      }


      if (format === 'optional' && value === undefined) return '';
      return value;
    },
  },
  react: {
    wait: true,
    bindStore: false,
    bindI18n: false,
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'img'],
    useSuspense: false,
  },
};

const nextI18Next = new NextI18Next(options);

const {
  config,
  consoleMessage,
  i18n,
  appWithTranslation,
  withTranslation,
  useTranslation,
  Trans,
  Link,
} = nextI18Next;

export {
  config,
  consoleMessage,
  i18n,
  appWithTranslation,
  withTranslation,
  useTranslation,
  Trans,
  Link,
};

export default nextI18Next;
