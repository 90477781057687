import { isObject, isEmpty, logWarning } from 'shared/helpers/utils';
import { isNil } from 'helpers/utils';

const removeEmptyFields = (obj, { removeFalsy } = {}) => {
  try {
    if (!isObject(obj)) {
      logWarning('Argument is not an object type: ', obj);

      return obj;
    }

    return Object.entries(obj)
      .reduce((acc, [key, value]) => {
        if ((isObject(value) && isEmpty(value)) || (removeFalsy && !value) || value === undefined || value === null) {
          return acc;
        }
        return { ...acc, [key]: value };
      }, {});
  } catch (e) {
    console.error(e);
    return obj;
  }
};

export default removeEmptyFields;
