const V2_API_URLS = {
  COUNTRIES: '/api/v2/countries',
  SCHOOLS: '/api/v2/schools',
  DEPARTMENTS: '/api/v2/departments',
  COURSES: '/api/v2/courses',
  PROFESSORS: '/api/v2/professors',
};

export {
  V2_API_URLS,
};
