import { isObject } from 'helpers/utils';
import removeEmptyFields from 'helpers/utils/removeEmptyFields';
import mapValues from 'lodash/mapValues';
import merge from 'lodash/merge';
import camelCase from 'lodash/camelCase';

import { MODEL_TYPES } from 'v2/redux/constants';

export const validateModelType = type => (typeof type === 'string') && MODEL_TYPES.includes(type);

export const normalizeModelType = type => camelCase(type);

export const checkModelType = (modelType) => {
  const isValid = modelType && validateModelType(modelType);

  if (!isValid) {
    console.error('Incorrect model type:', modelType);
  }

  return isValid;
};

export const denormalize = (entity, models, denormalizeNestingLevel = 1) => {
  try {
    if (!isObject(entity)) {
      throw new Error(`Model not found: ${entity}`);
    }

    const {
      id,
      type,
      attributes,
      relationships,
    } = entity;

    const result = type ? merge({ modelType: type, id }, attributes) : attributes;

    // If denormalizeNestingLevel is null, denormalize all levels.
    // This can cause errors if an entity contains circular relationships
    if (relationships && (denormalizeNestingLevel === null || (denormalizeNestingLevel > 0))) {
      // https://jsonapi.org/format/#document-resource-object-linkage
      // Resource linkage MUST be represented as one of the following:
      //   null for empty to-one relationships.
      //   an empty array([]) for empty to-many relationships.
      //   a single resource identifier object for non-empty to-one relationships.
      //   an array of resource identifier objects for non-empty to-many relationships.
      const includes = mapValues(relationships, (rel) => {
        const { data: relData } = rel;
        if (relData) {
          const isArrayData = Array.isArray(relData);

          let dataArray = isArrayData ? relData : [relData];

          dataArray = dataArray.map((data) => {
            const t = data.type;
            const modelsByType = models[t];

            if (!modelsByType) {
              console.error(`Models not found for type: ${t}`);
              return undefined;
            }
            const includedEntity = modelsByType[data.id];

            if (includedEntity) {
              const nestingLevel = denormalizeNestingLevel === null ? null : (denormalizeNestingLevel - 1);

              return denormalize(includedEntity, models, nestingLevel);
            }

            return undefined;
          }).filter(relItem => relItem !== undefined);

          return isArrayData ? dataArray : dataArray[0];
        }

        if (relData === undefined) {
          console.error('Incorrect relationship:', relData);
        }
        // Can be either null or undefined
        return relData;
      });

      return merge(result, removeEmptyFields(includes));
    }
    return result;
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

export default {};
