import React from 'react';
import { string, bool, func } from 'prop-types';

const Link = ({ defaultTag, onClickTag, nofollow, ...props }) => {
  // eslint-disable-next-line no-nested-ternary
  const Tag = props.href ? 'a' : (props.onClick ? onClickTag : defaultTag);

  const type = (Tag === 'button') ? 'button' : null;

  const isButtonLike = (onClickTag !== 'button' && !props.href && props.onClick);
  const role = isButtonLike ? 'button' : null;
  const tabindex = isButtonLike ? '0' : null;
  const rel = nofollow ? 'nofollow' : null;

  return <Tag type={type} role={role} tabIndex={tabindex} rel={rel} {...props} />;
};


Link.propTypes = {
  href: string,
  defaultTag: string,
  onClickTag: string,
  nofollow: bool,
  onClick: func,
};

Link.defaultProps = {
  defaultTag: 'span',
  onClickTag: 'button',
};


export default Link;
