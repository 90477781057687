const RECEIVE_CURRENT_USER_ID = 'common/user/RECEIVE_CURRENT_USER_ID';
const RECEIVE_USER_DATA = 'common/user/RECEIVE_USER_DATA';
const POST_REQUEST_UNLOCK_LIMIT = 'common/user/POST_REQUEST_UNLOCK_LIMIT';
const POST_REQUEST_UNLOCK_LIMIT_SUCCESS = 'common/user/POST_REQUEST_UNLOCK_LIMIT_SUCCESS';
const POST_REQUEST_UNLOCK_LIMIT_ERROR = 'common/user/POST_REQUEST_UNLOCK_LIMIT_ERROR';
const RESET_POST_REQUEST_UNLOCK_LIMIT_ERROR = 'common/user/RESET_POST_REQUEST_UNLOCK_LIMIT_ERROR';
const GET_USER_COURSES = 'common/user/GET_USER_COURSES';
const GET_USER_COURSES_SUCCESS = 'common/user/GET_USER_COURSES_SUCCESS';
const GET_USER_COURSES_ERROR = 'common/user/GET_USER_COURSES_ERROR';
const GET_ENROLLED_USER_COURSES = 'common/user/GET_ENROLLED_USER_COURSES';
const GET_ENROLLED_USER_COURSES_SUCCESS = 'common/user/GET_ENROLLED_USER_COURSES_SUCCESS';
const GET_ENROLLED_USER_COURSES_ERROR = 'common/user/GET_ENROLLED_USER_COURSES_ERROR';

export const IN_UNIVERSITY = 0;
export const IN_HIGH_SCHOOL = 1;
export const NOT_IN_SCHOOL = 2;
export const SCHOOL_NOT_LISTED = 3;

export {
  RECEIVE_CURRENT_USER_ID,
  RECEIVE_USER_DATA,
  POST_REQUEST_UNLOCK_LIMIT,
  POST_REQUEST_UNLOCK_LIMIT_SUCCESS,
  POST_REQUEST_UNLOCK_LIMIT_ERROR,
  RESET_POST_REQUEST_UNLOCK_LIMIT_ERROR,
  GET_USER_COURSES,
  GET_USER_COURSES_SUCCESS,
  GET_USER_COURSES_ERROR,
  GET_ENROLLED_USER_COURSES,
  GET_ENROLLED_USER_COURSES_SUCCESS,
  GET_ENROLLED_USER_COURSES_ERROR,
};
