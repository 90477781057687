import mapValues from 'lodash/mapValues';
import { checkModelType, denormalize } from 'v2/redux/utils';
import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import { MODEL_TYPES } from 'v2/redux/constants';
import pick from 'lodash/pick';
import { isObject } from 'helpers/utils';
import camelCase from 'lodash/camelCase';

// ============= PROPS GETTERS ==========

const normalizeModelType = (modelType, withIdSuffix) => {
  if (!modelType) return undefined;

  const normalizedType = camelCase(modelType);
  checkModelType(normalizedType);

  return withIdSuffix ? `${normalizedType}Id` : normalizedType;
};

const makeGetV2ModelIdProp = (modelType) => {
  const normalizedType = camelCase(modelType);

  checkModelType(normalizedType);
  return (state, props) => props[`${normalizedType}Id`];
};

export const getV2ModelIdProp = MODEL_TYPES.reduce((acc, type) => ({
  ...acc,
  [type]: makeGetV2ModelIdProp(type),
}), {});

export const getV2CacheKeyProp = (state, props) => props?.cacheKey;
export const getV2UIDProp = (state, props) => props?.uid;

export const getState = state => state;
export const getV2DeviceType = createSelector(
  getState,
  state => Object.entries(state.responsive).reduce((acc, entry) => {
    if (entry[1] === true) {
      return entry[0];
    }
    return acc;
  }),
);

// ============= COMMON SELECTORS =============
export const getV2Common = state => state.common;
export const getV2PageSub = state => getV2Common(state).page;

export const selectV2Models = createSelector(
  getV2Common,
  common => mapValues(common, 'data'),
);

export const getV2ModelTypeProp = (state, { modelType } = {}) => {
  const normalizedType = camelCase(modelType);
  checkModelType(normalizedType);

  return normalizedType;
};


const makeSelectV2Model = (modelType) => {
  const normalizedType = camelCase(modelType);
  checkModelType(normalizedType);

  return createSelector(
    selectV2Models,
    models => (models ? models[normalizedType] : null),
  );
};

// const makeSelectV2PagePropsModelId = (modelType) => {
//   const normalizedType = normalizeModelType(modelType, true);

//   return createSelector(
//     getV2PageSub,
//     (pageProps) => {
//       console.log('AAAAAAAA', pageProps, normalizedType);
//       return pageProps[normalizedType];
//     },
//   );
// };

// export const getV2PagePropsModelId = MODEL_TYPES.reduce((acc, type) => ({
//   ...acc,
//   [type]: makeSelectV2PagePropsModelId(type),
// }), {});

export const createSelectV2ModelsByType = (typeSelector, cacheSelector) => createCachedSelector(
  [typeSelector, selectV2Models],
  (modelType, models) => {
    if (!modelType) {
      console.error('model type is not defined');
    } else {
      const model = models[modelType];

      if (!model) {
        console.error('Incorrect model: ', modelType, model);
      } else {
        return model;
      }
    }

    return {};
  },
)(cacheSelector);


export const selectV2ModelByTypeProp = createSelectV2ModelsByType(getV2ModelTypeProp, getV2ModelTypeProp);


export const createModelPropByIdSelector = (modelSelector, idPropName, key) => createCachedSelector(
  modelSelector,
  model => model && model[key],
)(
  (state, props) => props[key] || '_default',
);

export const createEntityByIdSelector = (getEntityIdProp, selectEntityModel) => createCachedSelector(
  [
    selectV2Models,
    selectEntityModel,
    getEntityIdProp,
    (state, props, denormalizeNestingLevel) => denormalizeNestingLevel,
  ],
  (models, entityModel, entityId, denormalizeNestingLevel) => {
    if (!(entityId || (entityId === 0)) || !entityModel || !models) return undefined;

    const entityIdArray = Array.isArray(entityId) ? entityId : [entityId];

    const entities = entityIdArray.reduce((acc, id) => {
      if (entityModel[id]) {
        return [...acc, entityModel[id]];
      }

      return acc;
    }, []);

    const denormalizedEntities = entities.map(entity => denormalize(entity, models, denormalizeNestingLevel));

    return (!Array.isArray(entityId)) ? denormalizedEntities[0] : denormalizedEntities;
  },
)(
  (state, props, denormalizeNestingLevel) => {
    const entityId = getEntityIdProp(state, props) || '_default';
    const customCacheKey = getV2CacheKeyProp(state, props);

    const entityCacheKey = customCacheKey || (Array.isArray(entityId) ? entityId.join('-') : entityId);

    const cacheKey = (denormalizeNestingLevel ? `${entityCacheKey}__${denormalizeNestingLevel}` : entityCacheKey);

    return cacheKey;
  },
);

export const createRelationshipsByIdSelector = (getEntityIdProp, selectEntityModel) => createCachedSelector(
  [
    selectV2Models,
    selectEntityModel,
    getEntityIdProp,
    (state, props, idsOnly) => idsOnly,
  ],
  (models, entityModel, entityId, idsOnly) => {
    if (!entityId || !entityModel || !models) return null;

    const getId = rel => (isObject(rel) ? rel?.id : undefined);

    const entities = pick(entityModel, entityId);
    const relationships = Object.values(entities).map((entity) => {
      const rels = entity.relationships;

      if (!rels) return rels;


      return mapValues(rels, (rel) => {
        const data = rel?.data;

        if (!idsOnly) return data;


        // extract ids only
        if (Array.isArray(data)) {
          return data.reduce((acc, item) => {
            const id = item?.id;

            if (id || id === 0) {
              return [...acc, id];
            }

            return acc;
          }, []);
        }

        return getId(data);
      });
    });

    return (!Array.isArray(entityId)) ? relationships[0] : relationships;
  },
)(
  (state, props, idsOnly) => {
    const entityId = getEntityIdProp(state, props) || '_default';
    const customCacheKey = getV2CacheKeyProp(state, props);

    const entityCacheKey = customCacheKey || (Array.isArray(entityId) ? entityId.join('-') : entityId);

    const cacheKey = (idsOnly ? `${entityCacheKey}__${idsOnly}` : entityCacheKey);

    return cacheKey;
  },
);

export const selectV2ModelsDataByType = MODEL_TYPES.reduce((acc, type) => ({
  ...acc,
  [type]: type === 'model' ? selectV2ModelByTypeProp : makeSelectV2Model(type),
}), {});

export const selectV2ModelByIdProp = MODEL_TYPES.reduce((acc, type) => ({
  ...acc,
  [type]: createEntityByIdSelector(getV2ModelIdProp[type], selectV2ModelsDataByType[type]),
}), {});

export const selectV2RelationshipsByIdProp = MODEL_TYPES.reduce((acc, type) => ({
  ...acc,
  [type]: createRelationshipsByIdSelector(getV2ModelIdProp[type], selectV2ModelsDataByType[type]),
}), {});


// export const selectV2ModelByPagePropsId = MODEL_TYPES.reduce((acc, type) => ({
//   ...acc,
//   [type]: createEntityByIdSelector(getV2PagePropsModelId[type], selectV2ModelsDataByType[type]),
// }), {});


export const getV2ModelType = (_, __, modelType) => {
  const normalizedType = camelCase(modelType);
  checkModelType(normalizedType);

  return normalizedType;
};

export const selectV2ModelsByType = createSelectV2ModelsByType(getV2ModelType, getV2ModelType);

export const selectV2ModelsDataByTypeString = MODEL_TYPES.reduce((acc, type) => ({
  ...acc,
  [type]: type === 'model' ? selectV2ModelsByType : makeSelectV2Model(type),
}), {});


export const selectV2ModelById = MODEL_TYPES.reduce((acc, type) => ({
  ...acc,
  [type]: createEntityByIdSelector((_, modelId) => modelId, selectV2ModelsDataByTypeString[type]),
}), {});
